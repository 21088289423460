<!-- src/pages/MyPage.vue -->
<script setup>
import { cloneDeep } from 'lodash'
import ManseBirthMain from '@/pages/manse/components/ManseBirthMain.vue'
import ManseDaeunMain from '@/pages/manse/components/ManseDaeunMain.vue'
import ManseCard from '@/pages/manse/components/ManseCard.vue'

import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import {
  groupListOpen,
  isPopSajuGroupChange,
  closeGroupChange,
  openGroupSetting,
  isPopSajuGroupSetting,
  closeGroupSetting,
} from '@/composables/useGroup'

import { fastSearch, yearMonth, userGroups, userGroupDel, userGroupSave2 } from '@/api/Eoway'
import { getDeatil, updateGroup } from '@/api/UserGroup'
import { saveMemo } from '@/api/UserGroup'

import { useRoute } from 'vue-router'
import useAuth from '@/composables/useAuth'

//popup
import PopSajuGroupChange from '@/pages/saju/components/PopSajuGroupChange.vue'
import PopSajuGroupSetting from '@/pages/saju/components/PopSajuGroupSetting.vue'
import PopGanji from '@/pages/manse/components/PopGanji.vue'
import PopArea from '@/pages/manse/components/PopArea.vue'
import ComLatest from '@/pages/manse/components/ComLatest.vue'
import PopMansePrint from '@/pages/manse/components/PopMansePrint.vue'
import PopMemoReg from '@/pages/saju/components/PopMemoReg.vue'
import PopMemoList from '@/pages/saju/components/PopMemoList.vue'
import PopNameComponent from '@/pages/manse/components/PopName.vue'
import PopCardDetail from './components/PopCardDetail.vue'
import PopAddGroup from '../saju/components/PopAddGroup.vue'

//Alert
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'

//PrintComponet
import PrintItemComponent from '@/pages/manse/components/PrintItemComponent.vue'

import Snackbar from './components/Snackbar.vue'
import { DEV_MODE } from '@/main'

definePage({ meta: { layout: 'manse' } })

const route = useRoute()
const router = useRouter()
const { userInfo } = useAuth()

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

const isLoading = ref(false)
const colorMode = ref('fntcolor01')

const rsDataNor = ref(false)

const isOptionInput = ref(false),
  rsData01 = ref(false),
  isCheckboxChecked = ref(false)

const formData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
})

const rsDataAll = ref({})
const langMode = ref('CHNKOR')
const sortMode = ref('desc')

const transdata = ref({})
const inputdata = ref({})
const birthData = ref({})
const curDaeun = ref({})
const daeunsData = ref({})
const selectDaeun = ref({})
const birthJijanggan = ref({})
const birthTitle = ref('시일월년')
const gongDataVal = ref(null)
const isDaeunCheck = ref(false)
const birthFiveCnt = ref({})

const hapDataValEarth = ref(null)
const hapDataVal = ref(null)

const yearsData = ref({})
const monthsData = ref({})
const isDaeunYear = ref(true)
const isDaeunMonth = ref(true)
const daeunCurNum = ref(0)
const daeunYearCurNum = ref(0)
const daeunMonthCurNum = ref(0)
const seunIndex = ref(0)

const isSaved = ref(false)
const isShowBanner = ref(true)

// daeunCurNum, daeunYearCurNumm, daeunMonthCurNum 이
// 선택된 대운, 세운, 월운이므로 선택된 값으로 blylml 값 조합하는 방법?
const selectedBlylml = reactive({
  bl: '',
  yl: '',
  ml: '',
})
// 변경전의 대운/세운/월운
const prevBlylml = reactive({
  bl: '',
  yl: '',
  ml: '',
})
/** 대운적용 기능 사용 여부*/
const isApplyDaeun = ref(true)
// const isApplyDaeun = ref(DEV_MODE);

/** 사주조회 Params 저장 */
const searchParams = ref(null)

const isPopGanjiBox = ref(false)
const isPopAreaBox = ref(false)

const selectedGroup = ref({})
const selectedListItem = ref({})
const userGroupId = ref(null)
const userGroupName = ref('그룹미지정')

const currentCardIndex = ref(null)

const snackbar = ref(null)

//TODO: 전문가용
const toggleProMode = ref('nor')
const dataOrder = ref(['hour', 'day', 'month', 'year'])
const firstSegment = ref({})
const secondSegment = ref({})
const thirdSegment = ref({})

const changeProMode = (mode) => {
  toggleProMode.value = mode
  sessionStorage.setItem('toggleProMode', toggleProMode.value)
}

/** 한글과 한자 혼용 */
// 한자/한글 버전일 경우 프린트는 일부 한자 일부 한글
const showHanja = (value, position) => {
  return langMode.value === 'CHNKOR' ? five.getHanjaTxt(value, position) : value
}

async function setData() {
  if (sortMode.value === 'asc') {
    dataOrder.value = [dataOrder.value[0], ...dataOrder.value.slice(1).reverse()]
    dataOrderTit.value = [dataOrderTit.value[0], ...dataOrderTit.value.slice(1).reverse()]
  }

  if (rsDataAll.value) {
    const totalLength = rsDataAll.value.hundredsyears.reduce(
      (acc, item) => acc + (item.value ? item.value.length : 0),
      0,
    )

    const segmentLength = Math.ceil(totalLength / 3)
    firstSegment.value = rsDataAll.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter((v) => v.age <= segmentLength)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
    secondSegment.value = rsDataAll.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter(
        (v) => v.age > segmentLength && v.age <= segmentLength * 2,
      )
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])

    thirdSegment.value = rsDataAll.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter((v) => v.age > segmentLength * 2)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
  }
}

const goList = () => {
  const currentQuery = router.currentRoute.value.query
  router.push({
    path: '/saju',
    query: currentQuery, // 기존 쿼리 파라미터를 그대로 사용
  })
  //router.push('/saju')
}

watch(
  () => [route.params],
  async (newParams) => {
    // 콜백 함수를 async로 정의
    const [params] = newParams
    if (typeof params.id === 'undefined') {
      await router.push('/blank?path=/manse') // 비동기 처리
    } else {
      id.value = params?.id
      pgmode.value = params?.pgmode

      if (params.pgmode) {
        try {
          await getParams()
          await searchEoway()
        } catch (error) {
          alert('getParams : ' + error.response?.data?.message)
        }
      }
    }
  },
)

const handleLeapYearChange = () => {
  if (formData.value.isLeapYear) {
    formData.value.isLunar = null
  } else {
    formData.value.isLunar = false
  }
}

const handleLeapLunarChange = () => {
  formData.value.isLeapYear = null
}

const searchEowayDo = () => {
  isSaved.value = false
  isShowBanner.value = false
  formData.value.langYN = 'N'
  searchEoway()
}

const oldFormData = ref({})
const searchEoway = async () => {
  if (areaTxt.value === null) {
    formData.value.addressTxt = areaTxt.value
    formData.value.bornAreaId = null
  }

  let formValidate = true
  if (formData.value.gender === null) {
    showDialogAlert('성별을 확인해 주세요.')
    //alert('성별을 선택해 주세요.');
    formValidate = false
    return
  }
  if (
    formData.value.isLunar === null &&
    (formData.value.isLeapYear === null || formData.value.isLeapYear === false)
  ) {
    showDialogAlert('양력, 음력, 윤달을 선택해 주세요.')
    formValidate = false
    return
  }
  if (formData.value.year === '' || formData.value.month === '' || formData.value.day === '') {
    showDialogAlert('생년월일을 입력해주세요.')
    formValidate = false
    return
  }

  const year = parseInt(formData.value.year, 10)
  if (year < 1900 || year > 2100) {
    showDialogAlert('사주조회는 1900년~2100년까지 가능합니다.')
    formValidate = false
    return
  }
  if (
    (formData.value.hour === '' || formData.value.min === '') &&
    formData.value.birth_time !== true
  ) {
    showDialogAlert('출생시간을 입력해주세요.')
    formValidate = false
    return
  }

  if (pgmode.value !== 'rs') {
    formValidate = useFun.checkDateTimeValid({
      checkType: 'date',
      inputYear: formData.value.year,
      inputMonth: formData.value.month,
      inputDay: formData.value.day,
    })
    if (
      !formValidate ||
      isNaN(formData.value.year) ||
      isNaN(formData.value.month) ||
      isNaN(formData.value.day)
    ) {
      showDialogAlert('생년월일을 올바르게 입력해주세요.')
      formValidate = false
      return
    }
    formValidate = useFun.checkDateTimeValid({
      checkType: 'time',
      inputHour: formData.value.hour,
      inputMinute: formData.value.min,
    })
    if (
      (!formValidate || isNaN(formData.value.hour) || isNaN(formData.value.min)) &&
      formData.value.birth_time !== true
    ) {
      showDialogAlert('출생시간을 올바르게 입력해주세요.')
      return
    }
  }
  if (!formValidate) return
  try {
    isLoading.value = true
    const { month, day, hour, min } = formData.value
    formData.value.birth = `${formData.value.year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`
    if (pgmode.value !== 'rs') {
      formData.value.birthTime = `${hour.toString().padStart(2, '0')}:${min
        .toString()
        .padStart(2, '0')}`
    }
    // 시간모름 일때
    if (formData.value.birth_time) {
      formData.value.hour = '--'
      formData.value.min = '--'
      formData.value.birthTime = '25:00'
      formData.value.unknownTime = 'Y'
    }
    if (formData.value.birthTime === '25:00') formData.value.unknownTime = 'Y'
    if (!formData.value.unknownTime) formData.value.unknownTime = 'N'
    if (!formData.value.isLeapYear) formData.value.isLeapYear = false

    //지역값 없을 시 삭제하고 보내기
    if (formData.value.bornAreaId === null) {
      delete formData.value.bornAreaId
      delete oldFormData.value.bornAreaId
    }

    const params = reactive({}) // reactive로 초기화
    if (formData.value.langYN === 'Y') {
      Object.assign(params, oldFormData.value) // oldFormData의 값을 params에 할당
    } else {
      Object.assign(params, formData.value) // formData의 값을 params에 할당
    }

    // 대운적용을 위한 사주조회 params 저장
    searchParams.value = params

    const data = await fastSearch(params) // 저장목록 조회

    if (data.errmsg?.code === 1) {
      showDialogAlert(data.errmsg.msg)
      return
    }
    rsDataAll.value = data
    transdata.value = data.transdata
    inputdata.value = data.inputdata
    birthData.value = data.birthganji
    daeunsData.value = data.daeuns
    birthJijanggan.value = data.jijanggan
    rsData01.value = true

    yearsData.value = data.years
    monthsData.value = data.months

    daeunCurNum.value = data.transdata.curlucknum
    if (daeunCurNum.value > 100) {
      daeunCurNum.value = Math.max(...data.daeuns.sky.map((da) => da.num))
    }
    if (data.selectyear) {
      daeunYearCurNum.value = data.selectyear
    }
    if (data.selectmonth) {
      daeunMonthCurNum.value = data.selectmonth
    }
    curDaeun.value.sky = data.daeuns.sky.filter((da) => da.num === daeunCurNum.value)
    curDaeun.value.earth = data.daeuns.earth.filter((da) => da.num === daeunCurNum.value)

    hapDataVal.value = five
      .convertHap({ hap: data?.hap, isSky: true, langMode: langMode.value })
      .join('ㆍ')
    hapDataValEarth.value = five
      .convertHap({ hap: data?.hap, isSky: false, langMode: langMode.value })
      .join('ㆍ')

    //대운 반대로
    daeunsData.value.sky = reverseData(daeunsData.value.sky)
    daeunsData.value.earth = reverseData(daeunsData.value.earth)
    monthsData.value.sky = reverseData(monthsData.value.sky)
    monthsData.value.earth = reverseData(monthsData.value.earth)
    yearsData.value.sky = reverseData(yearsData.value.sky)
    yearsData.value.earth = reverseData(yearsData.value.earth)

    gongDataVal.value = useFun.convertGongmangTaewol(data)

    // 오행구성 수정
    birthFiveCnt.value = five.fiveColorCnt(curDaeun.value, data.birthganji?.five)

    //갤러리닫기
    rsDataNor.value = false
    if (pgmode.value === 'rs') rsDataNor.value = true
    //if (langMode.value) rsDataNor.value = true;

    //기존 데이터 저장해 두기
    oldFormData.value = cloneDeep(formData.value)
    //oldFormData.value = formData.value;

    // 사주 조회시 갤러리형 자동으로 펼쳐짐
    rsDataNor.value = true

    //FIXME: 전문가용 데이터 호출
    await setData()
  } catch (error) {
    console.error('Failed to load users:', error)
  } finally {
    //rsData01.value =false;
    isLoading.value = false
  }
}
const ManseBirthMainArea = ref(null)
const getDaeun = async (typ, item, curSelectedDaeun = {}) => {
  // 선택된 대운/세운/월운 한글 텍스트 추출 및 업데이트
  let luckSkyValue = curSelectedDaeun.sky.find((obj) => obj.num === item.num)?.value || ' '
  let luckEarthValue = curSelectedDaeun.earth.find((obj) => obj.num === item.num)?.value || ' '
  if (langMode.value === 'CHN') {
    // 한글 변환
    luckSkyValue = fiveMixin().getKorTxt(luckSkyValue, 'sky')
    luckEarthValue = fiveMixin().getKorTxt(luckEarthValue, 'earth')
  }
  const luckText = luckSkyValue + luckEarthValue
  updateSelectedBlylml({ typ, luckText })

  switch (typ) {
    case 'month':
      daeunMonthCurNum.value = item.num
      break
    case 'seun':
      if (prevBlylml.yl !== selectedBlylml.yl) await getMonth(item)
      break
    case 'daeun':
      if (prevBlylml.bl !== selectedBlylml.bl) {
        await getSeun(item)
        curDaeun.value.sky = curSelectedDaeun.sky.filter((da) => da.num === item.num)
        curDaeun.value.earth = curSelectedDaeun.earth.filter((da) => da.num === item.num)
        // 오행구성데이터 수정
        birthFiveCnt.value = five.fiveColorCnt(curDaeun.value, birthData.value?.five)
      }
      break
  }
}
const getSeun = async (item) => {
  daeunCurNum.value = item?.num
  const formParams = {}
  selectDaeun.value = item // 배열이 0 부터 시작이어서 -1 로 수정
  formParams.birthYear = inputdata.value.year.toString()
  formParams.daun = daeunCurNum.value.toString()
  formParams.hanja = birthData.value?.sky?.day?.value
  formParams.seun = 0
  formParams.lang = formData.value.lang
  isDaeunYear.value = true
  const result = await yearMonth(formParams)
  yearsData.value = result.years
  // 월운 호출
  await getMonth(result.years.sky[0])
  yearsData.value.sky = reverseData(yearsData.value.sky)
  yearsData.value.earth = reverseData(yearsData.value.earth)
  daeunYearCurNum.value = yearsData.value.sky[yearsData.value.sky.length - 1].num
}
const getMonth = async (item) => {
  seunIndex.value = item?.num
  const formParams = {}
  formParams.birthYear = inputdata.value.year
  formParams.daun = `${daeunCurNum.value}`
  formParams.hanja = birthData.value?.sky?.day?.value
  formParams.seun = `${item?.num}`
  formParams.lang = formData.value.lang
  isDaeunMonth.value = true
  daeunYearCurNum.value = item?.num
  const result = await yearMonth(formParams)
  monthsData.value = result.months
  monthsData.value.sky = reverseData(monthsData.value.sky)
  monthsData.value.earth = reverseData(monthsData.value.earth)
  daeunMonthCurNum.value = monthsData.value.sky[monthsData.value.sky.length - 1].num
}

/** 대운적용 천간 데이터 */
const hapBlDataSky = ref(null)
/** 대운적용 지지 데이터 */
const hapBlDataEarth = ref(null)

/** '대운적용' 버튼 클릭시 */
const clickDaeunCheck = async () => {
  isDaeunCheck.value = !isDaeunCheck.value
  if (isDaeunCheck.value) {
    await applyDaeun({ flag: true })
  }
}

/** 선택된 대운/세운/월운 업데이트 */
const updateSelectedBlylml = ({ typ, luckText }) => {
  if (typ === 'daeun') {
    prevBlylml.bl = selectedBlylml.bl
    selectedBlylml.bl = luckText
  } else if (typ === 'seun') {
    prevBlylml.yl = selectedBlylml.yl
    selectedBlylml.yl = luckText
  } else if (typ === 'month') {
    prevBlylml.ml = selectedBlylml.ml
    selectedBlylml.ml = luckText
  }
}

/** 대운적용 API 호출 */
const applyDaeun = async ({ flag, isLangChanged = false }) => {
  if (!isApplyDaeun.value) return
  const blylml = selectedBlylml.bl + selectedBlylml.yl + selectedBlylml.ml
  const isBlChanged = prevBlylml.bl !== selectedBlylml.bl

  if (isDaeunCheck.value && ((flag && isBlChanged) || isLangChanged)) {
    try {
      DEV_MODE && console.log('applyDaeun blylml:', blylml)

      isLoading.value = true

      const params = {
        ...searchParams.value,
        blCheck: 'Y',
        blylml,
      }

      let data = await fastSearch(params)

      prevBlylml.bl = selectedBlylml.bl

      if (data) {
        hapBlDataSky.value = five
          .convertHap({ hap: data?.hap_bl, isSky: true, langMode: langMode.value })
          .join('ㆍ')
        hapBlDataEarth.value = five
          .convertHap({ hap: data?.hap_bl, isSky: false, langMode: langMode.value })
          .join('ㆍ')
      } else {
        hapBlDataSky.value = null
        hapBlDataEarth.value = null
      }
    } catch (e) {
      DEV_MODE && console.log('manse applyDaeun error:', e)
    } finally {
      isLoading.value = false
    }
  }
}

/* //FIXME:  순서변경 */
const sortChange = () => {
  sortMode.value = sortMode.value === 'desc' ? 'asc' : 'desc'
  birthTitle.value = birthTitle.value.split('').reverse().join('')
  dataOrder.value.reverse()
  reverseDaeunData()
}

const reverseDaeunData = () => {
  daeunsData.value.sky = reverseData(daeunsData.value.sky)
  daeunsData.value.earth = reverseData(daeunsData.value.earth)
  monthsData.value.sky = reverseData(monthsData.value.sky)
  monthsData.value.earth = reverseData(monthsData.value.earth)
  yearsData.value.sky = reverseData(yearsData.value.sky)
  yearsData.value.earth = reverseData(yearsData.value.earth)

  const currYear = new Date().getFullYear()
  const foundYearData = yearsData.value.sky.find((item) => item.num === currYear)

  if (foundYearData) {
    daeunYearCurNum.value = foundYearData.num
  } else {
    daeunYearCurNum.value = yearsData.value.sky[yearsData.value.sky.length - 1].num
  }

  const foundMonthData = monthsData.value.sky.find((item) => item.num === new Date().getMonth() + 1)

  if (foundMonthData && daeunYearCurNum.value === currYear) {
    daeunMonthCurNum.value = foundMonthData.num
  } else {
    daeunMonthCurNum.value = monthsData.value.sky[monthsData.value.sky.length - 1].num
  }

  rsDataAll.daeuns.sky = daeunsData.sky
  rsDataAll.daeuns.earth = daeunsData.earth

  //TODO: 전문가용 재호출
  setData()
}
const reverseData = (data) => {
  return Object.values(data).reverse()
}

/*글자 및 색상변경*/
const searchEowayTrans = (mode) => {
  langMode.value = mode
  if (mode === 'CHNKOR') {
    formData.value.lang = 'KOR'
    oldFormData.value.lang = 'KOR'
  } else {
    formData.value.lang = mode
    oldFormData.value.lang = mode
  }
  formData.value.langYN = 'Y'
  searchEoway()

  if (isDaeunCheck.value) applyDaeun({ isLangChanged: true })
}
const searchEowayTransColor = (mode) => {
  colorMode.value = mode
}

const id = ref(null)
const pgmode = ref(null)

/*갤러리*/
const handleDefaultReport = () => {
  rsDataNor.value = !rsDataNor.value
  let scrollTopVal = 1300
  if (pgmode.value === 'rs') {
    scrollTopVal = 1100
  }
  if (rsDataNor.value) {
    setTimeout(() => {
      const currentScrollY = window.scrollY
      window.scrollTo({
        top: currentScrollY + scrollTopVal,
        behavior: 'smooth',
      })
    }, 1000)
  }
}

onMounted(async () => {
  id.value = route.params?.id
  pgmode.value = route.params?.pgmode

  // API 조회
  if (pgmode.value) {
    try {
      await getParams()
      await searchEoway()
    } catch (error) {
      showDialogAlert('getParams : ' + error.response?.data?.message)
    }
  }
  if (pgmode.value === 'rs') {
    isShowBanner.value = false
  }
})
const memoContent = ref('')
const memoCount = ref(0)
async function getParams() {
  DEV_MODE && console.log('getParams 호출됨')
  try {
    const result = await getDeatil(id.value)

    const { integrationBirth } = result
    //const dateTime = integrationBirth.split(''); // ["1999-01-01", "10:10:00"]
    const dateTime = integrationBirth.split('T') // ["1999-01-01", "10:10:00"]
    const [year, month, day] = dateTime[0].split('-') // ["1999", "01", "01"]
    const [hour, min] = dateTime[1]?.split(':') // ["10", "10"]

    formData.value.birthTime = result.birthTime

    formData.value.year = year // "1999"
    formData.value.month = month.padStart(2, '0') // "01"
    formData.value.day = day.padStart(2, '0') // "01"
    formData.value.hour = hour.padStart(2, '0') // "10"
    formData.value.min = min.padStart(2, '0') // "10"
    formData.value.userName = result.guestUserName
    // 선택된 그룹정보 저장
    selectedGroup.value = result
    userGroupId.value = result.userGroup?.userGroupId
    userGroupName.value = result.userGroup?.name || '그룹미지정'

    memoCount.value = result.memoCount
    //selectGender.value = result.gender === 'MEN' ? 1 : 0;
    formData.value.gender = result.gender === 'MEN' ? 1 : 0
    formData.value.isLunar = result.isLuna === null ? false : result.isLuna
    formData.value.isLeapYear = result.isLeapYear
    formData.value.addressTxt = result.bornArea
    formData.value.bornAreaId = `${result.bornAreaId}`
    formData.value.option1 = result.option1
    formData.value.option2 = result.option2
    formData.value.option3 = result.option3
    formData.value.option4 = result.option4
    formData.value.option5 = result.option5
    DEV_MODE && console.log('선택된그룹:', userGroupName.value)
  } catch (error) {
    goEorror01()
    console.log(error)
    // 에러 처리
    throw error // 에러가 발생하면 다시 throw
  }
}

// 스낵바 띄우기
function triggerSnackbar(message) {
  snackbar.value.showSnackbar(message)
}

//저장하기(로그인체크)
async function regSave() {
  checkUserLoginAndOpenDialog().then((isLoggedIn) => {
    if (isLoggedIn) {
      const year = parseInt(formData.value.year, 10)
      if (year < 1900 || year > 2100) {
        showDialogAlert('사주조회는 1900년~2100년까지 가능합니다.')
        return
      }
      showDialogConfirm(
        '저장하시겠습니까?',
        '저장목록에서 확인 가능합니다.',
        '저장',
        // await getUserGroups,
        () => getUserGroups(),
      )
      /*
  // eslint-disable-next-line no-restricted-globals
  if (confirm('저장하시겠습니까? \n저장목록에서 확인 가능합니다.')) {
    // 00> groupId 가져오기
    await getUserGroups()
    //await save();
  }
    */
    }
  })
}

function handleBirthTime() {
  if (formData.value.birth_time) {
    formData.value.hour = '--'
    formData.value.min = '--'
    isCheckboxChecked.value = true
  } else {
    formData.value.hour = ''
    formData.value.min = ''
    isCheckboxChecked.value = false
  }
}

//그룹정보 조회
async function getUserGroups() {
  if (userGroupId.value) {
    formData.value.userGroup = { userGroupId: userGroupId.value }
  } else {
    formData.value.userGroupId = null
    formData.value.userGroup = null
    /*
    const data = await userGroups({})
    formData.value.userGroupId = data?.find((item) => item.isFixed)?.userGroupId
    formData.value.userName = formData.value.userName
    formData.value.userGroup = { userGroupId: formData.value.userGroupId }
    */
  }
  await save()
}

//저장하기
async function save() {
  const { month, day, hour, min } = formData.value

  formData.value.birth = `${formData.value.year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
  formData.value.birthTime = `${hour.toString().padStart(2, '0')}:${min
    .toString()
    .padStart(2, '0')}`
  formData.value.lunar = transdata.value.lunar
  formData.value.solar = transdata.value.solar

  const params = reactive({ ...formData.value })
  if (formData.value.gender === 0 || formData.value.gender === '0') {
    params.gender = 'WOMEN'
  } else {
    params.gender = 'MEN'
  }

  // 맞는 해결책인지는 모르겠으나 일단 테스트
  params.guestUserName = formData.value.userName

  params.sajugangi = `${birthData.value.sky.hour.value}${birthData.value.sky.day.value}${birthData.value.sky.month.value}${birthData.value.sky.year.value}`
  params.sajugangi += `${birthData.value.earth.hour.value}${birthData.value.earth.day.value}${birthData.value.earth.month.value}${birthData.value.earth.year.value}`
  try {
    const result = await userGroupSave2(params)
    //메모가 있을 경우 저장하기
    if (memoContent.value) {
      const param = {}
      const paramUser = {}
      paramUser.guestUserId = result.guestUserId
      param.guestUser = paramUser
      param.content = memoContent.value
      saveMemo(param)
        .then(() => {
          showDialogAlert('저장 되었습니다.')
        })
        .catch((error) => {
          showDialogAlert(error.response.data?.message)
        })
    } else {
      showDialogAlert('저장 되었습니다.')
    }
  } catch (error) {
    showDialogAlert('에러 발생:', error)
    console.error('에러 발생:', error)
  }
  // 스낵바
  // triggerSnackbar('저장되었습니다')

  isSaved.value = true
}

//그룹선택저장
async function changeGroup(selectedGroup) {
  DEV_MODE && console.log('changeGroup호출됨')
  userGroupId.value = selectedGroup[0]?.userGroupId
  userGroupName.value = selectedGroup[0]?.name
  if (pgmode.value === 'rs') {
    try {
      DEV_MODE && console.log('id.value: ', id.value)
      const result = await getDeatil(id.value)
      DEV_MODE && console.log('result in changeGroup', result.value)
      let selectUserGroup = result
      if (!selectUserGroup.userGroup) {
        selectUserGroup.userGroup = {} // 빈 객체로 초기화
      }
      selectUserGroup.userGroup.userGroupId = userGroupId.value
      const upResult = await updateGroup(selectUserGroup)
      selectedGroup.value = selectUserGroup
      DEV_MODE && console.log('selectedGroup in changeGroup', selectedGroup.value)
    } catch (error) {
      showDialogAlert(error.message || 'Error occurred while fetching details.') // 에러 메시지 표시
    }
    getParams()
  }
  isPopSajuGroupChange.value = false
}

function handleGroupChange(selectedGroupItem) {
  checkUserLoginAndOpenDialog().then((isLoggedIn) => {
    if (isLoggedIn) {
      groupListOpen()
      selectedGroup.value = selectedGroupItem
      selectedListItem.value = selectedGroupItem
      DEV_MODE && console.log('selectedGroup', selectedGroup.value)
      DEV_MODE && console.log('selectedListItem', selectedListItem.value)
    }
  })
}

//삭제
async function delComLast() {
  const param = {
    guestUsersIds: id.value,
  }

  // eslint-disable-next-line no-restricted-globals
  // if (confirm('삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다.')) {
  //   await userGroupDel(param)
  //   router.push('/saju')
  // }
  showDialogConfirm('삭제하시겠습니까?', '삭제된 정보는 다시 볼 수 없습니다.', '삭제', async () => {
    await userGroupDel(param)
    router.push('/saju')
  })
}

//정보 조회 값 세팅
const searchInput = (item) => {
  Object.assign(formData.value, item)
  const timeParts = item.time.split(':') // 시간을 ":"를 기준으로 분리
  const hour = timeParts[0] // 시간 부분을 가져옴
  const minute = timeParts[1] // 분 부분을 가져옴

  formData.value.hour = hour // 시간을 formData의 속성에 할당
  formData.value.min = minute // 분을 formData의 속성에 할당
}

// 지역검색시
const handleFocusArea = () => {
  formData.value.option4 = true
  if (!isPopAreaBox.value) {
    isPopAreaBox.value = true
  }
}

const areaTxt = ref('')
//지역선택
const selectAreaFinish = (selectAreaItem) => {
  areaTxt.value = `${selectAreaItem.value.korCountryName}, ${selectAreaItem.value.korCityName} (${selectAreaItem.value.longitudeType} ${selectAreaItem.value.degrees}도 ${selectAreaItem.value.minutes}분)`
  formData.value.bornAreaId = `${selectAreaItem.value.id}`
  isPopAreaBox.value = false
  formData.value.addressTxt = `${selectAreaItem.value.korCountryName}, ${selectAreaItem.value.korCityName} (${selectAreaItem.value.longitudeType} ${selectAreaItem.value.degrees}도 ${selectAreaItem.value.minutes}분)`
}

// 갤러리형 용어 설명
const isPopManseDetail = ref(false)
const closePopManseDetail = () => {
  isPopManseDetail.value = false
}

const isPopMansePrint = ref(false)
const closePopMansePrint = () => {
  isPopMansePrint.value = false
}

const dialogPrint = ref(null)
async function printManse() {
  let cnt = 0
  cnt = Object.keys(rsDataAll.value)?.length
  if (cnt > 0) {
    // 출력물 세션 저장
    sessionStorage.setItem('printData', JSON.stringify(rsDataAll.value))
    sessionStorage.setItem('printformData', JSON.stringify(formData.value))
    sessionStorage.setItem('sortMode', sortMode.value)
    sessionStorage.setItem('langMode', langMode.value)
    sessionStorage.setItem('toggleProMode', toggleProMode.value)
    isPopMansePrint.value = true
  } else {
    showDialogAlert('사주조회 후 이용하여 주시기 바랍니다.')
  }
}

const selectedMemoListItem = ref({})
const selectedMemoListItemVal = ref(null)
const memoModeType = ref(null)

//메모 열기
const openMemoRegFirst = () => {
  checkUserLoginAndOpenDialog().then((isLoggedIn) => {
    if (isLoggedIn) {
      const item = {}
      item.userName = formData.value.userName
      item.birthTime = `${formData.value.hour}:${formData.value.min}`
      item.gender = useFun.getGenderTxt3(formData.value.gender)
      item.solar = transdata.value.solar
      item.lunar = transdata.value.lunar
      item.age = transdata.value.age
      item.ageKor = transdata.value.kor_age
      item.firstReg = true
      item.guestUserMemoContent = memoContent.value
      item.guestUserId = id
      if (pgmode.value === 'rs' && memoCount.value > 0) {
        selectedMemoListItem.value = item
        openMemoList()
      } else if (pgmode.value === 'rs' && memoCount.value === 0) {
        selectedMemoListItem.value = item
        selectedMemoListItemVal.value = item
        memoModeType.value = 'reg'
        openMemoReg()
      } else {
        selectedMemoListItem.value = item
        selectedMemoListItemVal.value = item
        selectedMemoListItemVal.value.content = memoContent.value
        memoModeType.value = 'reg_first'
        openMemoReg()
      }
    }
  })
}

//메모저장
const saveMemoFirst = (mContent) => {
  memoContent.value = mContent
  memoCount.value = 1
}

import { getMemoList } from '@/api/UserGroup'

const memoData = ref([])
const memoListData = ref({})
const listGroupMemoRefresh = async (memoTxt = '') => {
  try {
    const params = {}
    if (memoTxt !== '') params.content = memoTxt
    params.guestUserId = selectedMemoListItem.value.guestUserId
    params.sort = 'createdAt.desc'
    params.size = 30
    const data = await getMemoList(params) // 그룹리스트 조회
    memoData.value = data.content

    // listData 초기화
    Object.keys(memoListData.value).forEach((key) => {
      delete memoListData.value[key]
    })

    // 데이터 그룹화
    memoData.value.forEach((data) => {
      const createdAt = new Date(data.createdAt)
      const monthYear = `${createdAt.getFullYear()}.${`0${createdAt.getMonth() + 1}`.slice(-2)}`

      if (!memoListData.value[monthYear]) {
        memoListData.value[monthYear] = []
      }

      memoListData.value[monthYear].push(data)
    })
    memoCount.value = memoData.value.length
  } catch (error) {
    console.error('Failed to load users:', error)
  }
}

const listGroupMemoDel = () => {
  showDialogAlert('삭제되었습니다.', '', listGroupMemoRefresh)
}

function openMemoListReg(selectedItem) {
  selectedMemoListItem.value = selectedItem
  selectedMemoListItemVal.value = null
  //selectedMemoListItem.value.content = ''
  memoModeType.value = 'list'
  openMemoReg()
}

function openMemoListView(selectedMemo, selectedItem) {
  selectedMemoListItemVal.value = selectedItem
  selectedMemoListItem.value = selectedMemo
  //memoModeType.value = 'view'
  memoModeType.value = 'edit'
  openMemoReg()
}

function updateMemoEdit() {
  memoModeType.value = 'edit'
}

const handleReportClick = async (typ) => {
  let cnt = 0
  cnt = Object.keys(rsDataAll.value)?.length
  if (cnt > 0 && typ === 'reset') {
    /*
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('새로운 사주로 입력 하시겠습니까? 입력한 내용이 초기화 됩니다.')) {
      return
    }
    */
    await showDialogConfirm(
      '새로운 사주로 입력 하시겠습니까?',
      '입력한 내용이 초기화 됩니다.',
      '확인',
      () => handleReportClick2(typ),
    )
    return
  } else {
    handleReportClick2(typ)
  }
}

const handleReportClick2 = (typ) => {
  if (
    rsData01.value === false &&
    (areaTxt.value === null || areaTxt.value === '') &&
    formData.value.gender === null &&
    formData.value.year === '' &&
    formData.value.month === '' &&
    formData.value.hour === '' &&
    formData.value.day === '' &&
    formData.value.min === ''
  ) {
    showDialogAlert('초기화 상태입니다. ')
    return
  }
  if (typ === 'reset') {
    rsData01.value = false
    rsDataNor.value = false
    areaTxt.value = null
    userGroupName.value = '그룹미지정'
    isSaved.value = false
    isShowBanner.value = true
    formData.value = {
      year: '',
      month: '',
      hour: '',
      day: '',
      min: '',
      gender: null,
      userName: '',
      isLunar: false,
      isLeapYear: null,
      bornAreaId: null,
      birth_time: null,
      option1: true,
      option2: true,
      option3: false,
      option4: true,
      addressTxt: null,
      lang: 'KOR',
      userGroupId: null,
    }
    //popAreaCity.value = null
    // 출력물 비우기
    rsDataAll.value = {}
  }
  if (typ === 'rs01') {
    rsData01.value = true
    rsDataNor.value = false
  }
  if (typ === 'nor') {
    rsDataVip.value = false
    rsDataNor.value = true
  }
  if (typ === 'vip') {
    rsDataNor.value = false
    rsDataVip.value = true
  }
}
const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)

const dialogConfrim = ref(null)

//FIX 알림창
const showDialogAlert = (title, text = '') => {
  dialogTitle.value = title
  if (text) dialogText.value = text
  dialogAlert.value.openAlertDialog()
}
const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달

  dialogConfrim.value.openConfirmDialog()
}

const checkLength = (fieldId, value) => {
  if (fieldId === 'monthField') {
    if (value.length === 4) {
      const monthField = document.getElementById(fieldId)
      monthField.focus()
    }
  } else {
    if (value.length === 2) {
      const monthField = document.getElementById(fieldId)
      monthField.focus()
    }
  }
}

const isPopNameBox = ref(false)

const openUserName = () => {
  isPopNameBox.value = true
}

const onChangeName = async (name) => {
  const result = await getDeatil(id.value)
  result.userName = name
  // 변경된 이름 들어가도록
  result.guestUserName = name
  const upResult = await updateGroup(result)
  isPopNameBox.value = false
  inputdata.value.name = name
}

const checkOption4Checkbox = () => {
  if (!formData.value.option4 && !(areaTxt.value === null || areaTxt.value === '')) {
    showDialogAlert('지역을 적용하신 경우, 한국시만 적용됩니다.')
    formData.value.option4 = true
  }
}
const goYoutube = () => {
  window.open('https://www.youtube.com/@nangmancalendar', '_blank')
}

/** 로그인 confirm dialog */
// 게스트일 경우 로그인 요청
const checkUserLoginAndOpenDialog = async () => {
  const userId = await getUserLogin()

  if (!userId) {
    showDialogConfirm('로그인 후 이용 가능합니다.', '', '로그인', () => router.push('/auth/login'))
    return false
  }
  return true // 로그인된 상태일 경우 true 반환
}

/** 용어 설명 팝업 열기 */
const openDetail = (index) => {
  currentCardIndex.value = index
  isPopManseDetail.value = true
}

/** 그룹명은 5글자까지만 표시 */
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...'
  }
  return text
}

/* rs 모드 일 경우 본인 정보가 아닐 경우 /error/error01 */
const goEorror01 = () => {
  router.push('/error/error01')
}
</script>

<template>
  <div class="content-main bg-body-gray" :class="[colorMode, langMode]">
    <section class="inner-base manse-hd-wrap">
      <div class="banner-box mb10" :class="{ rspage: pgmode === 'rs' }" style="margin-right: 12px">
        <img
          src="/src/assets/images_manse/banner02.png"
          alt=""
          style="cursor: pointer; margin-top: 46px"
          @click="goYoutube"
        />
      </div>
      <div class="spacer" />
      <section class="inner-base-3" :class="{ rspage: pgmode === 'rs' }">
        <section class="manse-search-box mb10" :class="{ rspageForm: pgmode === 'rs' }">
          <div class="tit-exp">*생년월일시와 옵션을 활용해서 사주팔자를 확인해보세요.</div>
          <VForm ref="contentForm">
            <div class="search-form-box">
              <div class="search-box w-short">
                <div class="item-box">
                  <div class="txt wd100">
                    <div class="txt-gender-box">
                      <input
                        v-model="formData.gender"
                        id="rd-genter-male"
                        name="gender"
                        type="radio"
                        class="rd-gender male"
                        value="1"
                      /><label class="" for="rd-genter-male">남자</label>
                      <input
                        v-model="formData.gender"
                        id="rd-genter-female"
                        name="gender"
                        type="radio"
                        class="rd-gender female"
                        value="0"
                      /><label class="line" for="rd-genter-female">여자</label>
                    </div>
                    <div class="txt-gender-box">
                      <input
                        id="date1"
                        type="radio"
                        name="isLunar"
                        class="rd-orange"
                        value="false"
                        v-model="formData.isLunar"
                        @click="handleLeapLunarChange"
                      /><label for="date1">양력</label>
                      <input
                        id="date2"
                        type="radio"
                        name="isLunar"
                        class="rd-orange"
                        value="true"
                        v-model="formData.isLunar"
                        @click="handleLeapLunarChange"
                      /><label class="line" for="date2">음력</label>
                      <input
                        id="date3"
                        type="checkbox"
                        name="isLeapYear"
                        class="rd-orange"
                        v-model="formData.isLeapYear"
                        @change="handleLeapYearChange"
                      /><label class="line" for="date3">윤달</label>
                    </div>
                    <span class="btn-right">
                      <button class="btn-bg-point" type="button" @click="isPopGanjiBox = true">
                        사주간지 입력
                      </button>
                    </span>
                  </div>
                </div>
                <div class="item-box">
                  <div class="txt-year-box">
                    <span class="input-relative">
                      <VTextField
                        v-model="formData.year"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="4"
                        @keydown.enter="focusNext('monthField')"
                        @input="checkLength('monthField', formData.year)"
                      >
                        <template #append-inner>
                          <span class="txt-r">년</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="monthField"
                        v-model="formData.month"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        @keydown.enter="focusNext('dayField')"
                        @input="checkLength('dayField', formData.month)"
                      >
                        <template #append-inner>
                          <span class="txt-r">월</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="dayField"
                        v-model="formData.day"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        @keydown.enter="focusNext('hourField')"
                        @input="checkLength('hourField', formData.day)"
                      >
                        <template #append-inner>
                          <span class="txt-r">일</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="hourField"
                        v-model="formData.hour"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        :disabled="isCheckboxChecked"
                        @keydown.enter="focusNext('minField')"
                        @input="checkLength('minField', formData.hour)"
                      >
                        <template #append-inner>
                          <span class="txt-r">시</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="minField"
                        v-model="formData.min"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        :disabled="isCheckboxChecked"
                        @keydown.enter="searchEowayDo()"
                      >
                        <template #append-inner>
                          <span class="txt-r">분</span>
                        </template>
                      </VTextField>
                    </span>
                    <span>
                      <input
                        id="birth_time"
                        v-model="formData.birth_time"
                        type="checkbox"
                        class="checkbox"
                        @change="handleBirthTime"
                      /><label class="label font" for="birth_time">시간 모름</label>
                    </span>
                  </div>
                </div>
                <div class="item-box">
                  <div class="txt-text-box">
                    <VTextField
                      v-model="formData.userName"
                      class="h-small inputNext"
                      placeholder="이름, 닉네임 (최대20자 입력, 선택사항)"
                      variant="underlined"
                      clearable
                      maxlength="20"
                    />
                    <!-- <div
                      class="input w100 mr10"
                      @click="handleFocusArea"
                    >
                      {{ areaTxt ? areaTxt : '출생 지역 검색 (해외 출생지 포함)' }}
                    </div> -->
                    <VTextField
                      ref="areaField"
                      v-model="areaTxt"
                      class="h-small inputNext"
                      placeholder="출생 지역 검색 (해외 출생지 포함)"
                      variant="underlined"
                      readonly
                      clearable
                      @click="handleFocusArea"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isOptionInput" class="search-box right">
                <div class="btn-mark-box">
                  <button class="btn-mark" type="button" @click="isOptionInput = false">
                    <span>닫기</span> <i class="i" />
                  </button>
                </div>
                <div class="item-box">
                  <div class="tit">
                    한국시 적용
                    <!-- <i class="btn-exp">설명</i> -->
                    <!-- @click="setKorTime"-->
                  </div>
                  <div class="txt">
                    <input
                      id="kor_time"
                      ref="korTimeCheckbox"
                      type="checkbox"
                      class="checkbox"
                      v-model="formData.option4"
                      @change="checkOption4Checkbox"
                    /><label class="label" for="kor_time">한국시 적용</label>
                    <div class="tit ml-60">
                      야자시/조자시
                      <!-- <i class="btn-exp">설명</i> -->
                    </div>
                    <div class="txt">
                      <input
                        id="chk0101"
                        type="checkbox"
                        class="checkbox"
                        v-model="formData.option1"
                      />
                      <label for="chk0101" class="label">체크</label>
                    </div>
                  </div>
                </div>
                <div class="item-box">
                  <div class="tit">
                    절기 선택
                    <!-- <i class="btn-exp">설명</i> -->
                  </div>
                  <div class="txt">
                    <input
                      id="ra0101"
                      type="radio"
                      class="radio"
                      name="ra01"
                      :value="true"
                      v-model="formData.option2"
                    /><label for="ra0101" class="label font">동지</label>
                    <input
                      id="ra0102"
                      type="radio"
                      class="radio"
                      name="ra01"
                      :value="false"
                      v-model="formData.option2"
                    /><label for="ra0102" class="label font">입춘</label>
                    <input
                      id="ra0103"
                      type="radio"
                      class="radio"
                      name="ra01"
                      :value="null"
                      v-model="formData.option2"
                    /><label for="ra0103" class="label font">모름</label>
                  </div>
                </div>
                <div class="item-box">
                  <div class="tit" style="display: none">현재 옵션저장</div>
                  <div class="txt" style="display: none">
                    <input id="opt_save" type="checkbox" class="checkbox" /><label
                      class="label line"
                      for="opt_save"
                    />
                    <span class="line-txt pointer" @click="isPopCurrentOptionBox = true"
                      >옵션보기</span
                    >
                  </div>
                </div>
                <div class="btn-box ml-auto">
                  <button class="sBtn nor" type="button" @click="handleReportClick('reset')">
                    <i class="icon-restart">아이콘</i>초기화
                  </button>
                  <button class="sBtn" type="button" @click="searchEowayDo()">
                    <i class="icon-search" />조회하기
                  </button>
                </div>
              </div>
              <div v-if="!isOptionInput" class="search-box right option">
                <div class="btn-mark-box">
                  <button class="btn-mark" type="button" @click="isOptionInput = true">
                    <span>옵션</span> <i class="i" />
                  </button>
                </div>
                <ul class="opt-list">
                  <li>
                    • 사주구성의 다양한 조건을 직접 설정하실 수 있도록 <br />야자시/조자시, 한국시,
                    지역시, 동지, 입춘 옵션을 제공합니다.
                  </li>
                  <li>• 최근 조회한 옵션은 자동저장 됩니다. (회원서비스)</li>
                  <!-- <li>
                    • 낭만만세력은 사주구성의 다양한 조건을 직접 설정하실 수 있도록 편의기능을 제공합니다.
                  </li>
                  <li>
                    • 선택 옵션 <span class="icon-arrow02" /> 야자시/조자시, 한국시, 지역시, 동지, 입춘
                  </li>
                  <li>
                    • 옵션의 뜻과 의미는 해당 단어를 클릭하여 확인 가능합니다.
                  </li>
                  <li>
                    • 최근 조회한 옵션으로 자동 저장됩니다.
                    <span class="line-btm">회원 전용 서비스</span>
                  </li> -->
                </ul>
                <div class="btn-box ml-auto">
                  <button class="sBtn nor" type="button" @click="handleReportClick('reset')">
                    <i class="icon-restart">아이콘</i>초기화
                  </button>
                  <button class="sBtn" type="button" @click="searchEowayDo()">
                    <i class="icon-search" />조회하기
                  </button>
                </div>
              </div>
            </div>
          </VForm>
          <!-- // end : search-form-box // -->
        </section>
        <!-- // end : manse-search-box // -->
      </section>
      <ComLatest
        ref="refComLatest"
        :pr-pgmode="pgmode"
        :pr-data="rsDataAll"
        @printclick="printManse()"
      />
    </section>
    <div v-if="isShowBanner && pgmode != 'rs'" class="intro-banner">
      <div class="banner-img"></div>
    </div>
    <section>
      <div v-if="isLoading" class="loading-full-wrap">
        <div class="inner-box">
          <div ref="loadingContainer" class="loading-container">
            <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
          </div>
        </div>
      </div>
      <transition name="fade" appear>
        <div v-if="rsData01" :class="{ rspageSaju: pgmode === 'rs' }">
          <section class="inner-base result-10-box">
            <section ref="targetDiv" class="result-01-box mb20">
              <div class="people-box">
                <div class="mem-info">
                  <div class="profile-box">
                    <div class="profile-img">
                      {{ birthData.sky.day.value }}
                    </div>
                    <!-- <span class="icon-search-s-red btn-set">검색</span> -->
                  </div>
                  <div class="name-box-d">
                    <div v-if="inputdata.name" class="name-gen">
                      <div class="name-d">
                        {{ inputdata.name }}
                      </div>
                      <div class="gen">({{ useFun.getGenderTxt(inputdata.gender) }})</div>
                    </div>

                    <div v-else class="name-d">- ({{ useFun.getGenderTxt(inputdata.gender) }})</div>
                    <div class="age">
                      <!-- {{ inputdata.name }} -->
                      {{ transdata?.kor_age }}세 (만{{ transdata?.man_age }}세)
                      <!-- {{ calculateAge() }}세 -->
                    </div>
                  </div>
                  <div v-if="pgmode === 'rs'" class="btn-mod-box">
                    <button v-if="pgmode === 'rs'" class="btn-modify" @click="openUserName()">
                      수정
                    </button>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="birth-box">
                  <div class="mb6">
                    <div class="mr32">
                      <span class="tits orange">양력</span>
                      <span class="txts"
                        >{{ useFun.formatCustomDate(transdata?.solar) }} ({{ transdata.week }})
                        {{
                          five.getBirthTimeTxt(
                            five.getTimeTxt2(inputdata.hour) +
                              ':' +
                              five.getTimeTxt2(inputdata.min),
                          )
                        }}
                      </span>
                    </div>
                    <div class="">
                      <span class="tits blue">음력</span>
                      <span class="txts">{{ useFun.formatCustomDate(transdata?.lunar) }}</span>
                    </div>
                  </div>
                  <div class="w-wide">
                    <span class="tits">옵션</span>
                    <span class="txts">
                      <span v-if="transdata?.bornArea?.county">
                        {{ transdata?.bornArea.county }}, {{ transdata?.bornArea.city }},
                      </span>
                      {{ useFun.formatOption4Txt(inputdata.koreantime) }}
                      {{ useFun.formatOption1Txt(inputdata.earlytime) }}
                      {{ useFun.formatOption2Txt(inputdata.dongji) }}
                    </span>
                  </div>
                </div>
                <div ref="hiddenBtn" class="btn-box btn-rd trans-btn-box" style="min-width: 610px">
                  <!-- //TODO : 전문가 버전 체크 -->
                  <span class="" v-if="toggleProMode === 'nor'">
                    <v-row v-model="btnLang01" class="btn-tooltip">
                      <button
                        v-if="langMode === 'CHNKOR'"
                        class="btn-rd btn-rd-38 chnkor"
                        @click="searchEowayTrans('CHN')"
                      >
                        한자/한글
                      </button>
                      <!-- @click="searchEowayTrans('CHNKOR')" -->
                    </v-row>
                    <v-row v-model="btnLang02" class="btn-tooltip">
                      <button
                        v-if="langMode === 'CHN'"
                        class="btn-rd btn-rd-38 chn"
                        @click="searchEowayTrans('KOR')"
                      >
                        한자
                      </button>
                    </v-row>
                    <v-row v-model="btnLang03" class="btn-tooltip">
                      <button
                        v-if="langMode === 'KOR'"
                        class="btn-rd btn-rd-38 kor"
                        @click="searchEowayTrans('CHNKOR')"
                      >
                        한글
                      </button>
                    </v-row>
                    <v-row v-model="btnfntcolor01" class="btn-tooltip">
                      <button
                        v-if="colorMode === 'fntcolor01'"
                        class="btn-rd btn-rd-38 fntcolor01"
                        @click="searchEowayTransColor('fntcolor02')"
                      >
                        색상변환01
                      </button>
                      <!-- @click="searchEowayTrans('CHNKOR')" -->
                    </v-row>
                    <v-row v-model="btnfntcolor02" class="btn-tooltip">
                      <button
                        v-if="colorMode === 'fntcolor02'"
                        class="btn-rd btn-rd-38 fntcolor02"
                        @click="searchEowayTransColor('fntcolor03')"
                      >
                        색상변환02
                      </button>
                    </v-row>

                    <v-row v-model="btnfntcolor03" class="btn-tooltip">
                      <button
                        v-if="colorMode === 'fntcolor03'"
                        class="btn-rd btn-rd-38 fntcolor03"
                        @click="searchEowayTransColor('fntcolor01')"
                      >
                        색상변환03
                      </button>
                    </v-row>
                  </span>
                  <button
                    class="btn-rd btn-rd-38 sorting"
                    :class="{ on: sortMode === 'asc' }"
                    @click="sortChange()"
                  >
                    순서변경
                  </button>
                  <button
                    v-if="toggleProMode === 'pro'"
                    class="btn-line ml-1 st-toggle"
                    @click="changeProMode('nor')"
                  >
                    <i class="i-round-100 gray"></i> 전문가용
                  </button>
                  <!-- 
                  //FIX 실서버 반영되면 안되어서 주석 처리
                  <button
                    v-if="toggleProMode === 'nor'"
                    class="btn-line ml-1 st-toggle orange"
                    @click="changeProMode('pro')"
                  >
                    일반인용 <i class="i-round-100 orange"></i>
                  </button> -->
                  <!-- <div class="" v-if="toggleProMode === 'nor'">
                    <button
                      class="btn-rd btn-rd-38 grally"
                      :class="{ on: rsDataNor === true }"
                      @click="handleDefaultReport()"
                    >
                      갤러리형
                    </button>
                  </div> -->
                  <!-- <v-btn
                    class="btn-nor"
                    @click="downloadAsImage"
                  >
                    다운로드 (test)
                  </v-btn> -->
                  <span class="line" />
                  <button class="btn-line txt-ellipsis" @click="handleGroupChange(selectedGroup)">
                    <span>{{ truncateText(userGroupName, 5) }}</span>
                  </button>
                  <button class="btn-line" @click="openMemoRegFirst()">
                    <span class="name">메모</span> <span class="count">{{ memoCount }}</span>
                  </button>
                  <span class="line" />
                  <button
                    v-if="pgmode !== 'rs'"
                    class="btn-save none-icon btn-sq-40 btn"
                    :class="{ active: isSaved }"
                    @click="regSave()"
                  >
                    {{ isSaved ? '저장완료' : '저장하기' }}
                  </button>
                  <button v-if="pgmode === 'rs'" class="btn-del-line" @click="delComLast()">
                    삭제
                  </button>
                  <button
                    v-if="pgmode === 'rs'"
                    class="btn-list list btn-sq-40 btn"
                    @click="goList()"
                  >
                    목록으로
                  </button>
                </div>
              </div>
              <!-- //TODO : 전문가 버전 체크 -->
              <div
                class="data-sec-01-box"
                style="display: flex; justify-content: center"
                v-if="toggleProMode === 'pro'"
              >
                <div class="date-pro-root-box">
                  <div class="pro-brith-wrap">
                    <div class="pro-birth-box">
                      <table width="100%" class="prtTable">
                        <tr>
                          <td
                            v-for="(item, index) in birthTitle.split('')"
                            :key="index"
                            class="fntTit"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td v-for="(item, index) in dataOrder" :key="index" class="h-big">
                            <div class="ganji-box">
                              {{ showHanja(rsDataAll?.birthganji?.sky?.[item]?.value, 'sky') }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td v-for="(item, index) in dataOrder" :key="index" class="h-big">
                            <div class="ganji-box">
                              {{ showHanja(rsDataAll?.birthganji?.earth?.[item]?.value, 'earth') }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td v-for="(item, index) in dataOrder" :key="index" class="fntNor">
                            {{ rsDataAll?.birthganji?.sky[item]?.six }}
                          </td>
                        </tr>
                        <tr>
                          <td v-for="(item, index) in dataOrder" :key="index" class="fntNor">
                            {{ rsDataAll?.birthganji?.earth[item]?.six }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="pro-divider"></div>
                    <div class="pro-current-box">
                      <div class="pro-currunt-list">
                        <div
                          v-for="(item, index) in rsDataAll?.daeuns?.sky"
                          :key="index"
                          class="row-item-box"
                          width="10%"
                          :class="{ active: rsDataAll?.selectdaeun === item.num }"
                        >
                          <div
                            class="txt-no"
                            :class="{ fntActive: rsDataAll?.selectdaeun === item.num }"
                          >
                            {{ item.num }}
                          </div>
                          <div class="txt-year">
                            {{ item.year }}
                          </div>
                          <span class="ganji-txt">
                            {{ showHanja(item.value, 'sky') }}
                          </span>
                          <span class="ganji-txt">
                            {{ showHanja(rsDataAll?.daeuns.earth[index].value, 'earth') }}
                          </span>
                          <div class="txt-siz">
                            {{ item.six }}
                          </div>
                          <div class="txt-earth">
                            {{ rsDataAll?.daeuns.earth[index].six }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gong-tae">
                    <span>공망 : {{ gongDataVal?.split(' / ')[0] }}</span>
                    <div class="gong-tae-line"></div>
                    <span>태월 : {{ gongDataVal?.split(' / ')[1] }}</span>
                  </div>
                  <div class="print-btm-box">
                    <div class="box01">
                      <PrintItemComponent
                        class="box-box"
                        :pr-item="firstSegment"
                        :storedLangMode="storedLangMode"
                      />
                    </div>
                    <div class="box01">
                      <PrintItemComponent
                        class="box-box"
                        :pr-item="secondSegment"
                        :storedLangMode="storedLangMode"
                      />
                    </div>
                    <div class="box01">
                      <PrintItemComponent
                        class="box-box"
                        :pr-item="thirdSegment"
                        :storedLangMode="storedLangMode"
                      />
                    </div>
                    <div class="table-bottom-line"></div>
                  </div>
                </div>
              </div>

              <!-- //TODO : 일반 버전 체크 -->
              <div class="data-sec-01-box" v-if="toggleProMode === 'nor'">
                <div class="data-0101-root-box">
                  <ManseBirthMain
                    ref="ManseBirthMainArea"
                    :pr-birth-data="birthData"
                    :pr-birth-jijanggan="birthJijanggan"
                    :pr-lang-mode="langMode"
                    :pr-birth-title="birthTitle"
                    :pr-transdata="transdata"
                    :pr-type="'birth'"
                    :pr-cur-daeun="curDaeun"
                  />
                  <div class="data-0101-state" :class="{ active: isDaeunCheck }">
                    <!--'active': daeunCurNum !== transdata.curlucknum--->
                    <button class="btn-daeun" @click="clickDaeunCheck">
                      대운 적용<i class="icon-check01" />
                    </button>
                    <div class="state-row sec-daeun-default">
                      <div class="name">사주 오행</div>
                      <div class="five-count-box data">
                        <span class="item-box"
                          ><em class="txt five03 ganji-txt">木</em
                          ><em class="cnt">{{ birthData?.five?.five1?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five01 ganji-txt">火</em
                          ><em class="cnt">{{ birthData?.five?.five2?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five05 ganji-txt">土</em
                          ><em class="cnt">{{ birthData?.five?.five3?.num }}</em></span
                        >

                        <span class="item-box"
                          ><em class="txt five04 ganji-txt">金</em
                          ><em class="cnt">{{ birthData?.five?.five4?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five02 ganji-txt">水</em
                          ><em class="cnt">{{ birthData?.five?.five5?.num }}</em></span
                        >
                      </div>
                    </div>
                    <div class="state-row sec-daeun-selected">
                      <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
                      <div class="five-count-box data">
                        <span class="item-box"
                          ><em class="txt five03 ganji-txt">木</em
                          ><em class="cnt">{{ birthFiveCnt?.five1?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five01 ganji-txt">火</em
                          ><em class="cnt">{{ birthFiveCnt?.five2?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five05 ganji-txt">土</em
                          ><em class="cnt">{{ birthFiveCnt?.five3?.num }}</em></span
                        >

                        <span class="item-box"
                          ><em class="txt five04 ganji-txt">金</em
                          ><em class="cnt">{{ birthFiveCnt?.five4?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five02 ganji-txt">水</em
                          ><em class="cnt">{{ birthFiveCnt?.five5?.num }}</em></span
                        >
                      </div>
                    </div>
                    <!-- <div class="state-row"> -->
                    <div class="state-row" :class="{ 'sec-daeun-default': isApplyDaeun }">
                      <div class="name">천간 변화</div>
                      <div class="data" :class="{ 'none-data': hapDataVal === '' }">
                        {{ hapDataVal || '원국 천간변화가 없습니다.' }}
                      </div>
                    </div>
                    <div v-if="isApplyDaeun" class="state-row sec-daeun-selected">
                      <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
                      <div class="data">
                        {{ isLoading ? '' : hapBlDataSky || '원국 천간변화가 없습니다.' }}
                      </div>
                    </div>

                    <!-- <div class="state-row"> -->
                    <div class="state-row" :class="{ 'sec-daeun-default': isApplyDaeun }">
                      <div class="name">지지 변화</div>
                      <div class="data" :class="{ 'none-data': hapDataValEarth === '' }">
                        {{ hapDataValEarth || '원국 지지변화가 없습니다.' }}
                      </div>
                    </div>
                    <div v-if="isApplyDaeun" class="state-row sec-daeun-selected">
                      <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
                      <div class="data">
                        {{ isLoading ? '' : hapBlDataEarth || '원국 지지변화가 없습니다.' }}
                      </div>
                    </div>
                    <div class="state-row">
                      <div class="name">공망 / 태월</div>
                      <div class="data">
                        {{ gongDataVal }}
                      </div>
                    </div>
                    <!-- <div class="state-row">
                      <div class="name">
                        태월
                      </div>
                      <div class="data">
                        {{ TaeDataVal }}
                      </div>
                    </div> -->
                    <!-- <div class="state-row">
                      <div class="name">
                        MBTI
                      </div>
                      <div class="data">
                        <span class="bg-point">로그인 후 사주로 보는 MBTI</span>
                      </div>
                    </div> -->
                  </div>
                  <div class="data-0101-banner">
                    <img src="/src/assets/images_manse/banner03.png" alt="" />
                  </div>
                </div>
                <div class="">
                  <div class="deaun-main-box">
                    <div class="tit-box">
                      <span>대운</span>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="daeunsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'daeun'"
                      :pr-cur-num="daeunCurNum"
                      @clickDaeun="getDaeun"
                      @updateBlylml="updateSelectedBlylml"
                    />
                  </div>
                  <div v-if="isDaeunYear" class="deaun-main-box">
                    <div class="tit-box">
                      <span>세운</span>
                      <button
                        class="btn-fold"
                        @click=";(isDaeunYear = false), (isDaeunMonth = false)"
                      >
                        펼침/닫기
                      </button>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="yearsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'seun'"
                      :pr-cur-num="daeunYearCurNum"
                      @clickDaeun="getDaeun"
                      @updateBlylml="updateSelectedBlylml"
                    />
                  </div>
                  <div v-if="isDaeunMonth" class="deaun-main-box">
                    <div class="tit-box">
                      <span>월운</span>
                      <button class="btn-fold" @click="isDaeunMonth = false">펼침/닫기</button>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="monthsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'month'"
                      :class="'month'"
                      :pr-cur-num="daeunMonthCurNum"
                      @clickDaeun="getDaeun"
                      @updateBlylml="updateSelectedBlylml"
                      @applyDaeun="applyDaeun"
                    />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </transition>
    </section>
    <transition name="fade" appear>
      <section v-if="rsDataNor && toggleProMode === 'nor'" :class="{ rspageSaju: pgmode === 'rs' }">
        <section ref="rsGalleryBox" class="hd-inner-base bg-tab-cont manse-btm-ect-list">
          <!-- <button class="btn-close-xs" @click="handleDefaultReport()">닫기버튼</button> -->
          <div class="tab-content-container">
            <div class="tab-content-box">
              <ManseCard
                :pr-data-all="rsDataAll"
                :pr-sort-mode="sortMode"
                :pr-lang-mode="langMode"
                @openDetail="openDetail"
              ></ManseCard>
            </div>
          </div>
        </section>
      </section>
    </transition>
  </div>
  <section v-if="pgmode === 'rs'" class="btn-com1 ceneter">
    <button class="btn-line w-large bg-w" @click="goList()">목록으로</button>
  </section>
  <!-- 요청으로 수정 - 사주 입력 bg color 끊어지는 문제 -->
  <!-- <div class="gap-big"></div>  -->

  <!--그룹선택 팝업-->
  <PopSajuGroupChange
    v-if="isPopSajuGroupChange"
    :pr-selected-group="selectedGroup"
    :isDialogVisibleChange="true"
    @click="closeGroupChange"
    @change="changeGroup"
  />

  <!--그룹관리 팝업-->
  <PopAddGroup
    v-if="isPopSajuGroupAdd"
    :currentTab="currentTab"
    @close="closeGroupAdd"
    @change="changeGroup"
    @refresh="groupListDataRefresh"
    @openSetting="openGroupSetting"
  />

  <!--메모등록 팝업-->
  <PopMemoReg
    v-if="isPopMemoReg"
    ref="popMemoListRef"
    :pr-selected-memo="selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-selected-memo-val="selectedMemoListItemVal"
    :pr-transdata="transdata"
    :pr-inputdata="inputdata"
    @saveMemoFirst="saveMemoFirst"
    @listGroupMemo="listGroupMemoRefresh"
    @listGroupMemoDel="listGroupMemoDel"
    @updateMemoEdit="updateMemoEdit"
    @close="closeMemoReg"
  />

  <!--메모목록 팝업-->
  <PopMemoList
    v-if="isPopMemoList"
    :pr-selected-memo="selectedMemoListItem"
    :pr-selected-memo-val="selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-memo-list-data="memoListData"
    @saveMemoFirst="saveMemoFirst"
    @close="closeMemoList"
    @openMemoView="openMemoListView"
    @open="openMemoListReg"
    @listMemo="listGroupMemoRefresh"
  />
  <!-- 사주간지입력 (팝업)-->
  <PopGanji
    v-if="isPopGanjiBox"
    @close="isPopGanjiBox = false"
    @searchinput="searchInput"
    @snackbar="triggerSnackbar('한글 사주간지를 다시 확인해 주세요.')"
  />

  <!-- 주소검색 (팝업)-->
  <PopArea
    v-if="isPopAreaBox"
    @close="isPopAreaBox = false"
    @searchinput="searchInput"
    @selectAreaFinish="selectAreaFinish"
  />

  <!--출력화면-->
  <PopMansePrint
    ref="dialogPrint"
    v-if="isPopMansePrint"
    @close="closePopMansePrint()"
    @click:outside="closePopMansePrint()"
  />

  <!-- 사주용어이해하기 -->
  <PopCardDetail
    ref="dialogDetail"
    v-if="isPopManseDetail"
    :currentCardIndex="currentCardIndex"
    @close="closePopManseDetail()"
    @click:outside="closePopManseDetail()"
  />

  <!--Alert창-->
  <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />

  <ConfirmComponent
    ref="dialogConfrim"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />

  <!--이름 수정-->
  <PopNameComponent
    v-if="isPopNameBox"
    :username="inputdata.name"
    @onChangeName="onChangeName"
    @close="isPopNameBox = false"
  />

  <!--스낵바-->
  <Snackbar ref="snackbar" />
</template>
<style lang="scss">
@import '@/assets/css/style.scss';
@import '@/assets/nangman_style/manse.scss';
@import '@/assets/nangman_style/manse_k.scss';
</style>

<style>
.rspage {
  height: 0;
  width: 0;
  visibility: hidden;
}
@media (min-width: 600px) {
  .v-dialog.v-dialog-sm .v-overlay__content,
  .v-dialog.v-dialog-lg .v-overlay__content,
  .v-dialog.v-dialog-xl .v-overlay__content {
    inline-size: auto !important;
  }
}
</style>
<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0.3;
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
