import { DEV_MODE } from '@/main';
import _ from 'lodash';

export default function fiveMixin() {
  /** 오행 10간 12지 데이터 */
  const ganjiJsonData = {
    "five":{
      "木":{"color":"#5e9f1c"},
      "火":{"color":"#c23535"},
      "土":{"color":"#db9823"},
      "金":{"color":"#949494"},
      "水":{"color":"#404040"},
    },
    "ganji":{
      "갑":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "甲":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "을":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "乙":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "병":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "丙":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "정":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "丁":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "무":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "戊":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "기":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "己":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "경":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "庚":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "신":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "辛":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "임":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "壬":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "계":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "癸":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "자":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "子":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "축":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "丑":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "인":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "寅":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "묘":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "卯":{"color":"#ffffff","backgroundColor":"#5e9f1c","borderWidth":0,"borderColor":"#00000000","five":"木"},
      "진":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "辰":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "사":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "巳":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "오":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "午":{"color":"#ffffff","backgroundColor":"#c23535","borderWidth":0,"borderColor":"#00000000","five":"火"},
      "미":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "未":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      
      "申":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "유":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "酉":{"color":"#ffffff","backgroundColor":"#949494","borderWidth":0,"borderColor":"#00000000","five":"金"},
      "술":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "戌":{"color":"#ffffff","backgroundColor":"#e59d1e","borderWidth":0,"borderColor":"#00000000","five":"土"},
      "해":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
      "亥":{"color":"#ffffff","backgroundColor":"#555555","borderWidth":0,"borderColor":"#00000000","five":"水"},
    }
  };
  
  /** 간지 텍스트의 오행 정보 확인 */
  const getGanjiColorFive = (txt) => ganjiJsonData.ganji[txt]?.five;

  /** 오행별 클래스 추가 */
  const getFiveClass = (fiveValue) => {    
    return {
      five01: fiveValue === '火' || fiveValue === '화',
      five02: fiveValue === '水' || fiveValue === '수',
      five03: fiveValue === '木' || fiveValue === '목',
      five04: fiveValue === '金' || fiveValue === '금',
      five05: fiveValue === '土' || fiveValue === '토',
      five06: fiveValue === '-' || fiveValue === '-',
    };
  };

  const getGanjiColor = (txt) => {
    const matchingFive = ganjiJsonData.ganji[txt]?.five;
    return getFiveClass(matchingFive);
  };

  const getGanjiColorOnly = (txt) => {
    if (txt === undefined || txt === '') return '';
    let $bgColor = '';
    const fiveValue = ganjiJsonData.value?.ganji[txt]?.five;

    if (fiveValue === '火' || fiveValue === '화') $bgColor = '#C23535';
    if (fiveValue === '水' || fiveValue === '수') $bgColor = '#404040';
    if (fiveValue === '木' || fiveValue === '목') $bgColor = '#5E9F1C';
    if (fiveValue === '金' || fiveValue === '금') $bgColor = '#949494';
    if (fiveValue === '土' || fiveValue === '토') $bgColor = '#DB9823';
    return $bgColor;
  };
  
  const getColorFive = (txt) => {
    const kor      = '갑을병정무기경신임계자축인묘진사오미신유술해甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥-';
    const fieColor = '목목화화토토금금수수수토목목토화화토금금토수목목화화토토금금수수수토목목토화화토금금토수-';
    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return getFiveClass(fieColor[idx]);
    }
    return '';
  }
  const getHanjaTxt = (txt, pos) => {
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';

    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';

    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;

    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return chn[idx];
    }
    return '';
  };
  
  const getHanjaTxt2 = (txt) => {
    const kor = '갑을병정무기경신임계자축인묘진사오미신유술해'
    const chn = '甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥'
    const idx = kor.indexOf(txt);
    if (idx !== -1) {
      return chn[idx];
    }
    return '';
  };

  const getKorTxt = (txt, pos) => {
    const skyKor = '갑을병정무기경신임계';
    const skyChn = '甲乙丙丁戊己庚辛壬癸';
  
    const earthKor = '자축인묘진사오미신유술해';
    const earthChn = '子丑寅卯辰巳午未申酉戌亥';
  
    const kor = pos === 'sky' ? skyKor : earthKor;
    const chn = pos === 'sky' ? skyChn : earthChn;
  
    const idx = chn.indexOf(txt);
    if (idx !== -1) {
      return kor[idx];
    }
    return '';
  };

  const formatGanJi = (txt, no) => {
    if (typeof (txt) === 'undefined') {
      return '';
    }
    const character = txt.charAt(no - 1);
    return character;
  };


  const fiveColorCnt = (curDaeun, curBirthFive) => {
    DEV_MODE && console.log("curBirthFive ---- ")
    DEV_MODE && console.log(curBirthFive)

    const fiveCnt = JSON.parse(JSON.stringify(curBirthFive))

    const fiveMap = {
      木: "five1",
      목: "five1",
      火: "five2",
      화: "five2",
      土: "five3",
      토: "five3",
      金: "five4",
      금: "five4",
      水: "five5",
      수: "five5",
    }

    const incrementNum = (element) => {
      const key = element?.five
      if (key && fiveMap[key]) {
        fiveCnt[fiveMap[key]].num += 1
      }
    }

    incrementNum(curDaeun?.sky[0])
    incrementNum(curDaeun?.earth[0])

    return fiveCnt
  }


  const convertHap = ({hap, isSky, langMode}) => {
    if (!hap) return []
    const result = []

    let _hap = []
    let _hyung = []
    let _chung = []
    let _pa = []
    let _hae = []

    const relationNames = {
      hap: langMode === 'CHN' ? '合' : '합',
      hyung: langMode === 'CHN' ? '刑' : '형',
      chung: langMode === 'CHN' ? '沖' : '충',
      pa: langMode === 'CHN' ? '破' : '파',
      hae: langMode === 'CHN' ? '害' : '해'
    }

    if (isSky) {
      if (!hap.sky) return []
      _hap = _.chunk([...hap.sky.hap], 2)
      _chung = _.chunk([...hap.sky.chung], 2)
    } else {
      _hap = _.chunk([...hap.earth.hap], 2)
      _hyung = _.chunk([...hap.earth.hyung], 2)
      _chung = _.chunk([...hap.earth.chung], 2)
      _pa = _.chunk([...hap.earth.pa], 2)
      _hae = _.chunk([...hap.earth.hae], 2)
    }

    for (const hapItem of _hap) {
      const [item1, item2] = hapItem
      result.push(`${item1.value}${item2.value}${relationNames.hap}`)
    }
    for (const hyungItem of _hyung) {
      const [item1, item2] = hyungItem
      result.push(`${item1.value}${item2.value}${relationNames.hyung}`)
    }
    for (const chungItem of _chung) {
      const [item1, item2] = chungItem
      result.push(`${item1.value}${item2.value}${relationNames.chung}`)
    }
    for (const paItem of _pa) {
      const [item1, item2] = paItem
      result.push(`${item1.value}${item2.value}${relationNames.pa}`)
    }
    for (const haeItem of _hae) {
      const [item1, item2] = haeItem
      result.push(`${item1.value}${item2.value}${relationNames.hae}`)
    }

    return result
  }

  const getBirthTimeTxt = (str) => {
    return str === '25:00' ? '--:--' : str;
  }
  const getTimeTxt2 = (time) => {
    time = String(time);
    return time?.padStart(2, '0');
  }

  return { ganjiJsonData, getFiveClass, getGanjiColor, getGanjiColorOnly, getGanjiColorFive, getColorFive, getHanjaTxt, getHanjaTxt2, getKorTxt, formatGanJi, fiveColorCnt, convertHap, getBirthTimeTxt, getTimeTxt2 }
};

