<!-- src/pages/MyPage.vue -->
<script setup>
import PurchaseComplete from './purchaseComplete.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
definePage({ meta: { layout: 'mypage' } })
import { useStore } from 'vuex'
const store = useStore()
const productId = computed(() => store.state.productId)

const radioContent = [
  {
    title: '베이직',
    desc: '30건',
    value: 'basic',
    standard_3: 1,
    discounted_3: 300,
    percent_3: 1,
    standard_6: 600,
    discounted_6: 560,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 900,
    percent_9: 1,
    icon_class: 'ico-basic icon-subscr',
  },
  {
    title: '클래식',
    desc: '100건',
    value: 'classic',
    standard_3: 1,
    discounted_3: 700,
    percent_3: 1,
    standard_6: 1300,
    discounted_6: 1200,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 1800,
    percent_9: 1,
    icon_class: 'ico-classic icon-subscr',
  },
  {
    title: '에너지',
    desc: '200건',
    value: 'power',
    standard_3: 1,
    discounted_3: 1000,
    percent_3: 1,
    standard_6: 2000,
    discounted_6: 1800,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 3000,
    percent_9: 1,

    icon_class: 'ico-power icon-subscr',
  },
  {
    title: '하모니',
    desc: '무제한',
    value: 'orchestra',
    standard_3: '',
    discounted_3: 2000,
    percent_3: 1,
    standard_6: 4000,
    discounted_6: 3700,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 6000,
    percent_9: 1,
    icon_class: 'ico-orchestra icon-subscr',
  },
]

const radioContent2 = [
  {
    title: '3개월',
    //discount: '',
    //per: '',
    //price: '월 900',
    value: 3,
    icon_class: '',
  },
  {
    title: '6개월',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 6,
    icon_class: '',
  },
  {
    title: '9개월',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 9,
    icon_class: '',
  },
]

const selectedRadio = ref('basic')
const selectedRadio2 = ref(3)
const goodsName = ref('베이지 / 월 이용료')
const goodsAmt = ref(900)
const usePeriod = ref('')
const pointAmt = ref(1000)
const currentPoints = ref(1000)
const agree = ref(false)
const paymentComplete = ref(false) // 완료 페이지 관련 상태변수. 필요시 수정
const completeNotice = '월 이용권 구매'

const dialogConfirm = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')

const calculateEndDate = (duration) => {
  const startDate = new Date() // 현재 날짜
  let endDate = ''

  endDate = new Date(startDate.setMonth(startDate.getMonth() + duration))
  endDate.setDate(endDate.getDate() - 1)

  // 날짜 형식을 YYYY.MM.DD로 변환
  return `${endDate.getFullYear()}.${String(endDate.getMonth() + 1).padStart(2, '0')}.${String(
    endDate.getDate(),
  ).padStart(2, '0')}`
}

const updateGoodsName = (value) => {
  updateGoods()
}
const updateGoodsName2 = (value) => {
  updateGoods()
}

onMounted(() => {
  updateGoods() // 컴포넌트가 마운트될 때 초기값에 따라 업데이트
})

const updateGoods = () => {
  const selectedItem = radioContent.find((item) => item.value === selectedRadio.value)
  const selectedItem2 = radioContent2.find((item) => item.value === selectedRadio2.value)

  if (selectedItem) {
    goodsName.value = `${selectedItem.title} / ${selectedItem2.title}(사주 저장 ${selectedItem.desc})`
    if (selectedItem2.value === 3) {
      goodsAmt.value = selectedItem.discounted_3
    } else if (selectedItem2.value === 6) {
      goodsAmt.value = selectedItem.discounted_6
    } else if (selectedItem2.value === 9) {
      goodsAmt.value = selectedItem.discounted_9
    } else {
      goodsAmt.value = selectedItem.standard_3 // 기본 가격
    }
  } else {
    goodsName.value = ''
    goodsAmt.value = 0
  }
  usePeriod.value = `${new Date().toLocaleDateString()} ~ ${calculateEndDate(selectedItem2.value)}`
}

const router = useRouter()
const goFinish = () => {
  router.push('/mypage/subscrFinish')
}
// ------  결제위젯 초기화 ------
const clientKey = 'test_ck_DpexMgkW36ZA2M2K2wME3GbR5ozO'
// 회원 결제 (Bill_+금액+기간+userid)
const userid = sessionStorage.getItem('authUserId')
const customerKey = 'Bill_1_1_' + userid
const tossPayments = TossPayments(clientKey)
const payment = tossPayments.payment({
  customerKey,
})

const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirm.value.openConfirmDialog()
}

const doBill = async () => {
  // 보유한 포인트가 결제 포인트보다 작을 경우 alert
  if (currentPoints.value < goodsAmt.value) {
    const nextUrl = '/mypage/subscrPoint'
    showDialogConfirm(
      '보유 낭만 포인트가 부족합니다.',
      '지금 바로 충전하시겠습니까?',
      '충전하기',
      () => router.push(nextUrl),
    )
  } else {
    await payment.requestBillingAuth({
      method: 'CARD', // 자동결제(빌링)는 카드만 지원합니다
      successUrl: window.location.origin + '/mypage/subscrFinishBill', // 요청이 성공하면 리다이렉트되는 URL
      failUrl: window.location.origin + '/mypage/subscrFinishBillFail', // 요청이 실패하면 리다이렉트되는 URL
      customerEmail: 'customer123@gmail.com',
      customerName: '김토스',
    })
  }
}
//mypage/subscrFinishBill?customerKey=3H3NxXvLfVUfZ_DPFyQ_61&authKey=bln_0dqwov9qwdx
//mypage/subscrFinishBill?customerKey=Bill_15078&authKey=bln_KKEp0AMP7wE

const transformedRadioContent = computed(() => {
  const selectedKey = selectedRadio2.value // 3, 6, 9 중 하나

  // 선택된 값에 따라 동적으로 필드명을 설정
  return radioContent.map((item) => ({
    ...item,
    standard: item[`standard_${selectedKey}`],
    percent: item[`percent_${selectedKey}`],
    final: item[`discounted_${selectedKey}`],
  }))
})

const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">월 이용권 구매</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    {{ productId }}
    <!-- 완료화면 -->
    <div v-if="paymentComplete">
      <PurchaseComplete :product="completeNotice" />
    </div>
    <!-- 기본 구매 화면 -->
    <div v-else>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-tit-box radio-wrap">
          <div v-for="(month, index) in radioContent2" :key="month.value" class="radio-group">
            <input
              :id="month.value"
              type="radio"
              class="radio"
              :name="month.title"
              v-model="selectedRadio2"
              :value="month.value"
              @change="updateGoods"
            />
            <label :for="month.value" class="label" :class="{ last: month.value === 9 }">{{
              month.title
            }}</label>
          </div>
        </div>
        <div class="gap-xxl2"></div>
        <!-- './src/@core/components/app-form-elements/CustomRadiosWithIconImg.vue' 파일에 있음 -->
        <CustomRadiosWithIconImg
          class="radio-custom-01"
          v-model:selected-radio="selectedRadio"
          :radio-content="transformedRadioContent"
          :grid-column="{ sm: '3', cols: '12' }"
          @update:selectedRadio="updateGoodsName"
        />
        <!-- <div class="gap-xxxl"></div> -->
        <!-- <CustomRadiosWithIconImg
        class="radio-custom-01 st-repo-btm"
        v-model:selected-radio="selectedRadio2"
        :radio-content="radioContent2"
        :grid-column="{ sm: '3', cols: '12' }"
        @update:selectedRadio="updateGoodsName2"
      /> -->
        <div class="gap-xxl2"></div>
        <div class="my-line-01"></div>
        <div class="gap-xxl2"></div>
        <div class="text-right">
          <div class="point-use-box">
            <span class="tit"> 보유 낭만 포인트 </span>
            <div class="user-point">
              <i class="icon-point"></i>
              <span class="remain-point">{{ formatNumber(currentPoints) }} p</span>
              <span class="point-txt-box"
                ><button class="btn" @click="pointIns()">충전</button></span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="gap-sm-2"></div>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-pt-btm-2">
          <dl>
            <dt>• 상품명</dt>
            <dd class="txt1">{{ goodsName }}</dd>
          </dl>
          <dl>
            <dt>• 결제 포인트</dt>
            <dd class="txt2">
              <span class="point-amt">{{ formatNumber(goodsAmt) }}P</span>
            </dd>
          </dl>
          <dl>
            <dt>• 이용 기간</dt>
            <dd class="txt1">{{ usePeriod }}</dd>
          </dl>
        </div>
        <div class="gap-xxl2"></div>
        <div class="bg-box-01">
          <input type="checkbox" class="checkbox" v-model="agree" />
          결제 내용에 동의합니다.
          <span class="txt-tos">이용약관</span>
        </div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn type="submit" style="width: 260px" :disabled="agree === false" @click="doBill()">
            결제하기
          </VBtn>
        </div>
      </div>
      <div class="point-info-box">
        <div class="tit"><span>결제 안내</span> <i class="icon-info"></i></div>
        <ul>
          <li>
            저장소 요금제는 선택하신 기간동안 까지만 이용이 가능하며, 기간이 종료된 후에는 추가
            결제를 진행한 후 이용이 가능합니다.
          </li>
          <li>
            결제 후 미사용 시 7일 이내에 환불이 가능합니다.
            <button class="btn-txt-line">환불안내 바로가기</button>
          </li>
        </ul>
      </div>
    </div>
    <ConfirmComponent
      ref="dialogConfirm"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />
  </div>
</template>
<style lang="scss" scoped>
.point-use-box {
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  .tit {
    color: var(--Gray-800, #333);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0 !important;
  }
  .txt1 {
    margin-right: 5px;
  }

  .v-field__input {
    text-align: right !important;
  }
}
</style>
