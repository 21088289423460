<script setup>
import { Inputbox, PopupModal, Timer } from '@/components/auth'
import useReset from '@/composables/useReset'
import lockIcon from '../../assets/images_manse/icon_password_lg.svg'
import { mypageResetPasswordAPIQuery } from '@/api/myPage'
import AlertComponent from '@/pages/manse/components/Alert.vue'

definePage({ meta: { layout: 'auth' } })

const { resetStep, newPassword, userPwdValidation, pwdCheckValidation, showPopup, inputHandler } =
  useReset()

/** 다음 버튼 활성화 여부 */
const isConfirmDisabled = computed(() => {
  if (
    pwdCheckValidation.isValid &&
    newPassword.userPwd &&
    newPassword.userPwd.length > 7 &&
    newPassword.pwdCheck &&
    newPassword.pwdCheck.length > 7
  ) {
    return true
  } else {
    return false
  }
})

const onClickConfirm = async () => {
  const param = {}
  param.password = newPassword.userPwd
  const rs = await mypageResetPasswordAPIQuery({ params: param })
  if (rs) {
    showDialogAlert('비밀번호가 등록 되었습니다.', '', '/mypage/myinfo')
  }
}

const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')
const nextUrl = '/auth/login'

const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title
  dialogText.value = text
  dialogNextUrl.value = nextUrl
  dialogAlert.value.openAlertDialog()
}
</script>

<template>
  <div class="inner">
    <div class="content">
      <div class="icon">
        <component :is="lockIcon" />
      </div>
      <div class="form-title">비밀번호 등록 안내 (최초 1회 등록)</div>
      <div class="form-description">
        낭만 만세력 회원님! 고객님의 소중한 개인정보보호와 <br />안전한 서비스 이용을 위해 비밀번호
        등록을 필수 권장합니다. <br />(마이페이지 진입 시, 사용됩니다.)
      </div>

      <div class="input-group">
        <Inputbox
          v-model="newPassword.userPwd"
          :max-length="15"
          data-type="userPwd"
          type="password"
          placeholder="비밀번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="userPwdValidation.validationMessage"
        />
        <Inputbox
          v-model="newPassword.pwdCheck"
          :max-length="15"
          data-type="pwdCheck"
          type="password"
          placeholder="비밀번호를 한 번 더 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="pwdCheckValidation.validationMessage"
        />
      </div>

      <ul>
        <li>8~15자의 영문, 숫자, 특수문자 조합으로 입력해 주세요.</li>
        <li>
          연속적인 숫자 추출하기 쉬운 개인정보 및 아이디와 비슷한 비밀번호는 사용하지 않는 것을
          권장합니다.
        </li>
      </ul>

      <button
        class="confirm-btn btn"
        :class="{ active: isConfirmDisabled }"
        :disabled="!isConfirmDisabled"
        @click="onClickConfirm"
      >
        비밀번호 등록하기
      </button>
    </div>
  </div>
  <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>
