import axios from 'axios'

const endpoint = import.meta.env.VITE_APP_GW_URL
const systemId = import.meta.env.VITE_APP_SYSTEMID
const userService = `${endpoint}/users/${systemId}`

/** 사용자 정보 요청 API */
export const getUserInfoAPI = async () => {
  const userId = sessionStorage.getItem('authUserId')
  if (userId) {
    const url = `${userService}/mobile/get`
    const response = await axios.get(url)
    return response.data
  }
}

/** 내 정보 페이지 이메일 재설정 API */
export const mypageResetEmailAPI = async ({ params }) => {
  const url = `${userService}/mypage/email/reset`
  const response = await axios.post(url, null, { params })
  return response.data
}

/** 내 정보 페이지 휴대폰 번호 재설정 API */
export const mypageResetMobileAPI = async ({ params }) => {
  const url = `${userService}/mypage/mobile/reset`
  const response = await axios.post(url, null, { params })
  return response.data
}

/** 내 정보 페이지 비밀번호 재설정 API */
export const mypageResetPasswordAPI = async ({ params }) => {
  const url = `${userService}/mypage/password/reset`
  const response = await axios.post(url, null, { params })
  return response.data
}

/** 내 정보 페이지 비밀번호 재설정 API */
export const mypageResetPasswordAPIQuery = async ({ params }) => {
  const url = `${userService}/mypage/password/reset`
  const queryString = new URLSearchParams(params).toString()
  const response = await axios.post(`${url}?${queryString}`, null)
  return response.data
}

/** sns 계정 비밀번호 등록 확인 API */
export const mypageExistPasswordAPI = async () => {
  const url = `${userService}/password/exist`
  const response = await axios.get(`${url}`)
  return response.data
}

/** 내 정보 페이지 재설정 API */
export const mypageResetInfoAPI = async ({ type, params }) => {
  const url = `${userService}/mypage/${type}/reset`
  const response = await axios.post(url, null, { params })
  return response.data
}

/**
 * 기본정보 수정 요청 API
 *
 * data: { name, nickname, email, mobileNo }
 */
export const updateBasicInfoAPI = async ({ data }) => {
  const url = `${userService}/mobile/saju/update/info`
  await axios.put(url, data)
}

/** 부가정보 수정 요청 API */
export const updateAdditionalInfoAPI = async ({ data }) => {
  const url = `${userService}/mobile/saju/update/additional`
  await axios.put(url, data)
}

/** 이메일 주소 중복 확인 API */
export const checkEmailDuplicationAPI = async (email) => {
  const url = `${userService}/exist/email/${email}`
  const response = await axios.get(url)
  return response.data
}

export const checkPasswordAPI = async ({ data }) => {
  const url = `${userService}/password/check`
  const response = await axios.post(url, data)
  return response.data
}

/** 회원탈퇴 */
export const withdrawMemberAPI = async (params) => {
  const url = `users/${systemId}/mypage/withdraw`
  const response = await axios.post(url, null, { params })
  return response.data
}
