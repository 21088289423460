<script setup>
import {
  getGrouplist,
  saveGroup,
  delGroup,
  modifyGroup,
  getGroupTotal,
  updateGroupOrder,
  getRealTotal
} from '@/api/UserGroup'
import { onUnmounted, ref } from 'vue'
import draggable from 'vuedraggable'
//popup
import PopGroupDel from '@/pages/saju/components/PopGroupDel.vue'
import IconOrderBtn from '@/components/svg/IconOrderBtn.vue'
import { DEV_MODE } from '@/main'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'

/** 탭 변경 */
// const currentTab = ref(0); // 나중에 그룹목록이면 0, 순서 변경이면 1로 바꾸면 될 것 같다
const props = defineProps({
  currentTab: {
    type: Number,
    default: 0,
  },
})
const currentTabValue = ref(props.currentTab)
const selectedTabIndex0 = ref(null)
const selectedTabIndex1 = ref(null)

const isDialogVisibleSetting = ref(true)
const emit = defineEmits()

const popGroupName = ref('')
const delSelectedId = ref(null)
const isPopGroupDelBox = ref(false)
const activeItem = ref(null)
const selectedTabIndex = ref(null)
const isEditMode = ref(false)
const selectedGroupId = ref(null)
const editedGroupName = ref('')
const dialogOpen = ref(false)

// 펼쳐보기
const isExpanded = ref(false)
const shouldShowExpandButton = ref(false)
const groupSetList = ref(null)

// 수정 활성화
const originalGroupName = ref('')

// 수정 중 닫기
const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)
const dialogConfrim = ref(null)

const close = () => {
  // 미완성이라 주석처리
  if(isEditMode.value && originalGroupName.value != editedGroupName.value ){
    showDialogConfirm(
      '그룹 편집을 닫으시겠습니까?',
      '변경 된 내용은 저장되지 않습니다.',
      '닫기',
      () =>{
          emit('close')
      }
    );
  } else {
    emit('close');
  }


  // emit('close')
}

const listData = ref([])
/** 순서 변경 전 원본 저장 tempListData */
const tempListData = ref([])
const fixedUserGroupIds = ref(null)
const getGroupListData = async () => {
  try {
    const params = {}
    const data = await getGrouplist(params) // 그룹리스트 조회
    listData.value = data
    tempListData.value = JSON.parse(JSON.stringify(data))
    //01> 미지정 그룹 아이디 가져오기
    fixedUserGroupIds.value = listData.value
      .filter((item) => item.name === '미지정')
      .map((item) => item.userGroupId)
    DEV_MODE && console.log('그룹리스트받는함수호출됨')
    checkScroll()
  } catch (error) {
    console.error('Failed to load users:', error)
  }
}
const groupTotal = ref(null)
const getGroupTotalData = async () => {
  try {
    const params = {} // ???
    const data = await getGroupTotal(params)
    groupTotal.value = data.userGroupCount
  } catch (error) {
    console.error('그룹 총개수를 가져오는데 실패했습니다', error)
  }
}
/** 사용자 사용 불가능한 그룹명 목록 - 즐겨찾기, 전체, 내 사주, 미지정 */
const fixedGroupList = computed(() => [
  ...listData.value.filter(group => group.isFixed).map(group => group.name),
  '전체',
  '즐겨찾기',
  '내사주',
]);
/** 사용가능한 그룹명 판별 */
const isUnavailableName = computed(() => fixedGroupList.value.includes(popGroupName.value));
/** 그룹 추가 버튼 활성화 상태 */
const disabledHandleAdd = computed(() => !popGroupName.value || isUnavailableName.value);

onMounted(async () => {
  await getGroupListData()
  await getGroupTotalData()
  await getSajuTotal()
  nextTick(() => {
    checkScroll()
  })
})

onUnmounted(() => {
  tempListData.value = []
})

// '순서 변경' 탭이 선택될 때 스크롤 여부 확인
watch(currentTabValue, (newTab) => {
  if (newTab === 1) {
    nextTick(() => {
      checkScroll()
    })
  }
})

watch(
  () => props.currentTab,
  (newTab) => {
    currentTabValue.value = newTab
    DEV_MODE && console.log('현재탭', currentTabValue.value)
  },
)

/** 모달창 외부 클릭시 닫히도록하는 watch(?) */
watch(isDialogVisibleSetting, (newVal) => {
  if (!newVal && !dialogOpen.value) {
    DEV_MODE && console.log('외부로 인식');
    close();
  }
});


watch(
  () => props.currentTab,
  (newTab) => {
    currentTabValue.value = newTab
    DEV_MODE && console.log('현재탭', currentTabValue.value)
  },
)

const handleAdd = () => {
  if (popGroupName.value === '' || popGroupName.value === null) {
    alert('그룹명을 등록해주세요.')
    popGroupName.focus()
    return
  }
  if (isUnavailableName.value) return

  const formParams = {}
  formParams.name = popGroupName.value
  saveGroup(formParams)
    .then(() => {
      // alert('등록 되었습니다.');
      popGroupName.value = ''
      getGroupListData()
      getGroupTotalData()
      emit('refresh', 'add')
      nextTick(() => {
        checkScroll()
      })
    })
    .catch((error) => {
      alert(error.response.data?.message)
    })
}

function openDelmsg() {
  isPopGroupDelBox.value = true
  delSelectedId.value = selectedGroupId.value
}
function handleDel() {
  const formParams = {}
  formParams.groupIds = delSelectedId.value
  formParams.undesignated = fixedUserGroupIds.value

  delGroup(formParams)
    .then(async () => {
      dcancdelPopGroup()
      popGroupName.value = ''
      selectedGroupId.value = null
      isEditMode.value = false
      await getGroupListData()
      getGroupTotalData()
      emit('refresh', 'del')
      nextTick(() => {
        checkScroll()
      })
    })
    .catch((error) => {
      alert(error.response.data?.message)
    })
}
function dcancdelPopGroup() {
  isPopGroupDelBox.value = false
}

/** '수정모드' 수정 */
// 바뀐 디자인은 for문 안에서 index를 받을 수 없어서 수정
function handleMod(userGroupId) {
  isEditMode.value = true
  selectedGroupId.value = userGroupId
  activeItem.value = userGroupId
  const selectedGroup = listData.value.find((item) => item.userGroupId === userGroupId)
  if (selectedGroup) {
    originalGroupName.value = selectedGroup.name // 원래 그룹명 저장
    editedGroupName.value = selectedGroup.name
  }
}

/** 선택된 그룹의 순서 변경 함수 */
const changeGroupOrder = ({ order }) => {
  if (!selectedGroupId.value) return

  // 현재 선택된 그룹의 인덱스
  const currentIndex = listData.value.findIndex(
    (item) => item.userGroupId === selectedGroupId.value,
  )
  if (currentIndex === -1) return

  // 순서를 변경할 대상 그룹
  const selectedGroup = listData.value[currentIndex]

  // 미지정 그룹은 순서 변경 불가
  if (selectedGroup.isFixed) return

  // 새로운 인덱스 계산
  let newIndex
  switch (order) {
    case 'first':
      newIndex = 0
      break
    case 'prev':
      newIndex = Math.max(0, currentIndex - 1)
      break
    case 'next':
      newIndex = Math.min(listData.value.length - 1, currentIndex + 1)
      break
    case 'last':
      newIndex = listData.value.length - 1
      break
    default:
      return
  }

  // 새로운 위치로 그룹 이동
  listData.value.splice(currentIndex, 1) // 기존 위치에서 제거
  listData.value.splice(newIndex, 0, selectedGroup) // 새로운 위치에 삽입

  // 미지정 그룹은 항상 마지막에 유지
  const mysaju = listData.value.filter((item) => item.name === '내 사주')
  const groupNone = listData.value.filter((item) => item.name === '미지정')
  const nonFixedGroups = listData.value.filter((item) => !item.isFixed)
  listData.value = [...mysaju, ...groupNone, ...nonFixedGroups]
  console.log('listData', listData.value)
}
/** 순서 변경 여부 확인 */
const isOrderChanged = () => {
  // tempListData와 listData에서 userGroupId만 추출하여 비교한다.
  const originalOrder = tempListData.value.map((item) => item.userGroupId)
  const currentOrder = listData.value.map((item) => item.userGroupId)
  return JSON.stringify(originalOrder) !== JSON.stringify(currentOrder)
}
/** 변경된 그룹 순서 저장 함수 */
const saveGroupOrder = async () => {
  const data = listData.value
    .map((item) => ({
      name: item.name,
      systemId: item.systemId,
      description: item.description,
      isFixed: item.isFixed,
      userGroupId: item.userGroupId,
    }))
    .reverse()

  try {
    await updateGroupOrder({ data })
    close()
    emit('refresh', 'order')
  } catch (e) {
    DEV_MODE && console.log('saveGroupOrder error:', e)
  }
}

function handleModSave() {
  const selectedGroup = listData.value.find((item) => item.userGroupId === selectedGroupId.value)
  if (!editedGroupName.value || !editedGroupName.value.trim()) {
    alert('그룹명을 등록해주세요.')
    return
  }

  // 수정된 이름을 리스트에 반영
  selectedGroup.name = editedGroupName.value
  const formParams = {}
  formParams.name = String(editedGroupName.value)
  formParams.userGroupId = selectedGroupId.value

  modifyGroup(formParams)
    .then(async () => {
      // alert('수정 되었습니다.');
      popGroupName.value = ''
      await getGroupListData()
      await getGroupTotalData()
      emit('refresh', 'update')
      nextTick(() => {
        checkScroll()
      })
      isEditMode.value = false
    })
    .catch((error) => {
      alert(error.response.data?.message)
    })
}

const tabClick = (id) => {
  selectedTabIndex0.value = id
}

/** 펼쳐보기 - 기본 높이보다 높은지 체크 */
const checkScroll = () => {
  if (groupSetList.value) {
    const fixedHeight = 182
    const actualHeight = groupSetList.value.scrollHeight
    const hasScroll = actualHeight > fixedHeight
    shouldShowExpandButton.value = hasScroll
    DEV_MODE && console.log('유저그룹리스트높이:', hasScroll, actualHeight)
  }
}

const expandList = () => {
  isExpanded.value = true
}

const change = (selectItem) => {
  const selectedGroup = selectItem.userGroupId
  const filteredData = listData.value.filter((item) => item.userGroupId === selectedGroup)
  emit('change', filteredData)
}

/** 드래그 후 순서 저장 */
const onDragEnd = () => {
  isOrderChanged()
}

/** 미지정 드래그 불가 */
const onMove = (event) => {
  const draggedElement = event.draggedContext.element
  const targetElement = event.relatedContext.element

  // 미지정 그룹은 드래그할 수 없음, 다른 그룹이 미지정 그룹 뒤로 이동하지 않음
  if (draggedElement.isFixed || targetElement?.isFixed) {
    return false
  }
  return true
}

/** 수정에서 그룹 추가 누르면 */
const changeEditMode = () => {
  isEditMode.value = !isEditMode.value
}

/** 수정버튼 활성화 여부 */
const isModified = computed(() => {
  return originalGroupName.value !== (editedGroupName.value ? editedGroupName.value.trim() : '');
})

/** 수정 중간에 닫을 경우 */
const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}, onCancel = () => {}) => {
  dialogTitle.value = title;
  dialogTitle2.value = title2;
  dialogText.value = text;
  dialogNextUrl.value = onConfirm;
  
  dialogConfrim.value.openConfirmDialog();
};

/** 총 저장 개수 */
const realTotal = ref(null)
const getSajuTotal = async () => {
  try {
    const params = {}
    const data = await getRealTotal(params)
    realTotal.value = data.totalCount
  } catch (error) {
    console.error('총 저장 개수 받아오기 실패:', error)
  }
}
</script>

<template>
  <VDialog v-model="isDialogVisibleSetting" class="dialog-st1 group-st1" max-width="600px" persistent>
    <VCard class="br16">
      <DialogCloseBtn variant="text" size="default" @click="close" />
      <div class="pop-inner-test">
        <div class="tab-tit-box">
          <div class="tab-header">
            <VTabs
              v-model="currentTabValue"
              background-color="transparent"
              show-arrows
              class="tab-container align-left"
            >
              <VTab>
                <span class="tab-title">그룹 관리({{ groupTotal }})</span>
              </VTab>
              <VTab>
                <span class="tab-title">순서 변경</span>
              </VTab>
            </VTabs>
          </div>
          <!-- <div class="close-button" @click="close()">Close</div> -->
          <button class="dial-close" @click="close" style="margin-bottom: 10px;"></button>
        </div>

        <div class="tab-divider"></div>

        <div class="pop-body pd-large">
          <VWindow v-model="currentTabValue">
            <!-- 그룹 목록 탭 내용 -->
            <VWindowItem :value="0">
              <div
                v-if="listData?.length > 0"
                class="group-set-list scroll"
                :class="{ expanded: isExpanded }"
                ref="groupSetList"
              >
                <div
                  v-for="(item, index) in listData"
                  :key="index"
                  class="area-item"
                  :class="[
                    'area-item',
                    {
                      active: !item.isFixed && selectedTabIndex0 === item.userGroupId,
                      'g-none': item.isFixed,
                    },
                  ]"
                  @click="tabClick(item.userGroupId)"
                >
                  <div v-if="!item.isFixed" @click="handleMod(item.userGroupId)" class="name">
                    <span>{{ item.name }}</span>
                    <span style="margin-left: 4px">({{ item.memberCount }})</span>
                  </div>
                  <!-- 미지정은 클릭되지 않음 -->
                  <div v-else class="name">
                    {{ item.name }}
                  </div>
                </div>
                <!-- 그룹 추가 -->
                <div v-if="isEditMode" @click="changeEditMode()" class="area-item">
                  <div class="name">+ 그룹 추가</div>
                </div>
              </div>
              <!--펼쳐보기-->
              <button
                v-if="shouldShowExpandButton && !isExpanded"
                @click="expandList"
                class="expand-btn"
              >
                펼쳐보기 +
              </button>
              <div class="notice">
                <span>* 활성화된 그룹을 선택하여 수정, 삭제가 가능합니다.</span><br />
                <span>* 내 사주, 미지정은 변경 할 수 없습니다.</span>
              </div>
              <!-- 수정모드일 경우 -->
              <div v-if="isEditMode" class="pop-search-box">
                <VTextField
                  v-model="editedGroupName"
                  class="input-box"
                  variant="outlined"
                  clearable
                  maxlength="20"
                  density="compact"
                  @keydown="(event) => handleKeyDown2(event, item)"
                ></VTextField>
                <button
                  class="btn-sm sumbit"
                  @click="handleModSave"
                  :class="{ 'disabled-modi': !isModified }"
                  :disabled="!isModified"
                >
                  수정
                </button>
                <button class="btn-sm orange2" @click="openDelmsg">삭제</button>
              </div>
              <!-- 수정모드가 아닐 경우 -->
              <div v-else class="pop-search-box">
                <VTextField
                  v-model="popGroupName"
                  class="input-box pop-text"
                  variant="outlined"
                  clearable
                  maxlength="20"
                  density="compact"
                  placeholder="최소 1자에서 20자까지 입력 가능"
                  @keyup.enter="getUserGroupList(isListType)"
                  @focus="wordFocus = true"
                  @blur="wordFocus = false"
                ></VTextField>
                <VBtn
                  :class="['obtn', { 'disabled-button': disabledHandleAdd }]"
                  :disabled="disabledHandleAdd"
                  @click="handleAdd()"
                >
                  그룹 추가
                </VBtn>
              </div>
            </VWindowItem>

            <!--순서 변경 탭 내용-->
            <VWindowItem :value="1">
              <div
                v-if="listData?.length > 0"
                class="group-set-list scroll"
                :class="{ expanded: isExpanded }"
                ref="groupSetList"
              >
                <draggable
                  v-model="listData"
                  :group="{ name: 'groupSetList', pull: false, put: false }"
                  @end="onDragEnd"
                  item-key="userGroupId"
                  :move="onMove"
                  class="draggable-div"
                >
                  <template #item="{ element, index }">
                    <div
                      :key="element.userGroupId"
                      class="area-item"
                      :class="{
                        active: activeItem === element.userGroupId,
                        'g-none': element.isFixed || selectedTabIndex1 === element.userGroupId,
                      }"
                    >
                      <div
                        v-if="!element.isFixed"
                        @click="handleMod(element.userGroupId)"
                        class="name"
                      >
                        <span>{{ element.name }}</span>
                        <span style="margin-left: 4px">({{ element.memberCount }})</span>
                      </div>
                      <!-- 미지정은 클릭되지 않음 -->
                      <div v-else class="name">
                        {{ element.name }}
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
              <!--펼쳐보기-->
              <button
                v-if="shouldShowExpandButton && !isExpanded"
                @click="expandList"
                class="expand-btn"
              >
                펼쳐보기 +
              </button>

              <div class="notice1">
                <span>* 활성화된 그룹을 선택한 후, 끌어서 원하는 위치로 이동 가능합니다. </span><br />
                <span>* 내 사주, 미지정은 변경 할 수 없습니다.</span>
              </div>
              <div class="pop-search-box">
                <div class="control-btn">
                  <span>순서 제어</span>
                  <div class="change-order-btn">
                    <IconOrderBtn type="first" @click="changeGroupOrder({ order: 'first' })" />
                    <IconOrderBtn type="prev" @click="changeGroupOrder({ order: 'prev' })" />
                    <IconOrderBtn type="next" @click="changeGroupOrder({ order: 'next' })" />
                    <IconOrderBtn type="last" @click="changeGroupOrder({ order: 'last' })" />
                  </div>
                </div>
                <VBtn
                  :class="['obtn', { 'disabled-button': !isOrderChanged() }]"
                  :disabled="!isOrderChanged()"
                  @click="saveGroupOrder"
                >
                  저장
                </VBtn>
              </div>
            </VWindowItem>
          </VWindow>
        </div>
      </div>
    </VCard>
  </VDialog>

  <!-- 그룹 삭제확인 메세지 -->
  <PopGroupDel v-if="isPopGroupDelBox" @del="handleDel()" @dcancel="dcancdelPopGroup()" />
  <ConfirmComponent
    ref="dialogConfrim"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>
